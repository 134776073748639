import React, { Component } from 'react'

export default class Slider extends Component {
    render() {
        return (
            <div className="form-group my-slider">
                <label>{this.props.label}: {this.props.value}</label>
                <div>
                    <input
                        type="range"
                        value={this.props.value}
                        max={this.props.max}
                        min={this.props.min}
                        step={this.props.step}
                        name={this.props.name}
                        onChange={this.props.onChange}>
                    </input>
                </div>
            </div>
        )
    }
}