import React, { Component } from "react";
import { removeTab } from "../../../actions/layoutActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MapContext } from "../../gis/Map";
import Input from "../../layout/form/Input";
import ColorLineInput from "../../layout/form/ColorLineInput";
import TextInput from "../../layout/form/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Fill, Stroke, Style, Text } from 'ol/style';
import { removeInteraction, addInteraction } from "../../../actions/mapActions";

import { Draw, Modify, Snap } from "ol/interaction";
import { Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";


export class EditLine extends Component {

    /* =========================================================== */
    /* lifecycle methods                                           */
    /* =========================================================== */
    constructor(props) {
        super(props);
        if (this.props.type === "editLineTab") {
            const feature = this.props.selectedLine;
            // STYLE FILL
            const styleStrokeString = feature.style_.stroke_.color_,
                strokeColorsOnly = styleStrokeString.substring(styleStrokeString.indexOf('(') + 1, styleStrokeString.lastIndexOf(')')).split(/,\s*/),
                styleStrokeRGB = {
                    r: strokeColorsOnly[0],
                    g: strokeColorsOnly[1],
                    b: strokeColorsOnly[2]
                };

            // STYLE TEXT
            const styleTextString = feature.style_.text_.fill_.color_,
                textColorsOnly = styleTextString.substring(styleTextString.indexOf('(') + 1, styleTextString.lastIndexOf(')')).split(/,\s*/),
                styleTextRGB  = {
                    r: textColorsOnly[0],
                    g: textColorsOnly[1],
                    b: textColorsOnly[2]
                },
                styleTextSizeString = feature.style_.text_.font_,
                styleTextSize = styleTextSizeString.slice(0, styleTextSizeString.indexOf("p"));

            this.state = {
                features: [feature],
                styleStrokeRGB: styleStrokeRGB,
                styleStrokeWidth: feature.style_.stroke_.width_,
                styleTextRGB: styleTextRGB,
                styleTextSize: styleTextSize,
                geometry: feature.getGeometry()
            };
        } else {
            this.state = {
                features: [],
                styleStrokeRGB: {r: 183, g: 28, b: 28},
                styleStrokeWidth: 3,
                styleTextRGB: {r: 0, g: 0, b: 0},
                styleTextSize: 16,
                geometry: undefined,
            };
        }

        // GIS
        // drawing only on new feature
        if (this.state.geometry === undefined) {
            this.draw = undefined;
        }
        // modify for new and edit feature
        this.modify = undefined;
        this.snap = undefined;

        // temp draw layer before saving to def layer (source)
        this.sourceTempDrawLayer = new VectorSource();

        // add feature to temp if edit 
        if (this.state.geometry !== undefined) {
            this.sourceTempDrawLayer.addFeature(this.props.selectedLine);
        }

        // temp draw layer before saving to def layer (layer)
        this.vectorTempDrawLayer = new VectorLayer({
            title: "tempDrawLayer",
            source: this.sourceTempDrawLayer
        });
    }

    componentDidMount() {
        // temp layer to make the draw
        this.context.getLayers().forEach(layer => {
            if (layer.get("title") === "drawLayer") {
                this.vectorDrawLayer = layer;
                this.sourceDrawLayer = layer.getSource();
            }
        });

        // add this temp layer to context map object
        this.context.addLayer(this.vectorTempDrawLayer);
        this.addLineInteraction();

    }

    /* =========================================================== */
    /* event handlers                                              */
    /* =========================================================== */


    addLineInteraction = () => {
        // Draw interaction
        // drawing only on new feature
        if (this.state.geometry === undefined) {
            this.draw = new Draw({
                source: this.sourceTempDrawLayer,
                type: "LineString"
            });
            this.context.addInteraction(this.draw);
            this.props.addInteraction(this.draw);
        }

        // Snap interaction
        this.snap = new Snap({ source: this.sourceTempDrawLayer });
        this.context.addInteraction(this.snap);
        this.props.addInteraction(this.snap);

        // Modify interaction
        this.modify = new Modify({ source: this.sourceTempDrawLayer });
        this.context.addInteraction(this.modify);
        this.props.addInteraction(this.modify);

        // On Drawend event 
        // /!\ only for new feature
        if (this.state.geometry === undefined) {
            this.draw.on(
                "drawend",
                function (evt) {
                    evt.feature.setStyle(this.setStyle(evt.feature));
                    this.setState({ features: [...this.state.features, evt.feature] });
                }.bind(this)
            );
        }
    };

    removeDrawInteraction = () => {
        if (this.props.interactions.length > 0) {
            this.props.interactions.forEach((item, index) => {
                this.context.removeInteraction(item);
                this.props.removeInteraction(item);
            });
        }
    };

    closeLineTab = () => {
        this.context.getLayers().forEach(layer => {
            if (layer.get('title') === "tempDrawLayer") {
                this.context.removeLayer(layer);
            }
        });
        this.removeDrawInteraction();
        this.props.removeTab(this.props.type);
    };


    onChange = e => {
        let key = e.currentTarget.name;
        let value = e.currentTarget.value;
        this.setState({ [key]: value }, this.upDateStyleFeatures);

    };

    onChangeColor = e => {
        //update drawed feature style
        this.setState({ "styleStrokeRGB": e.rgb }, this.upDateStyleFeatures);
    };

    onChangeColorTxt = e => {
        //update drawed feature style
        this.setState({ "styleTextRGB": e.rgb }, this.upDateStyleFeatures);
    };


    onChangeLabel = e => {
        this.updateLabelFeature(e.currentTarget.id, e.currentTarget.value);
    }

    setStyle = (feature) => {
        let txt = feature.get("label") ? feature.get("label").length : 1;
        return new Style({
            stroke: new Stroke({
                color: 'rgb('
                    + this.state.styleStrokeRGB.r + ', '
                    + this.state.styleStrokeRGB.g + ', '
                    + this.state.styleStrokeRGB.b + ')',
                width: this.state.styleStrokeWidth
            }),
            text: new Text({
                font: this.state.styleTextSize + 'px Calibri',
                placement: "Line",
                offsetX: 20 + (txt) + (parseInt(this.state.styleStrokeWidth, 10)),
                text: feature.get("label"),
                fill: new Fill({
                    color: 'rgb('
                        + this.state.styleTextRGB.r + ', '
                        + this.state.styleTextRGB.g + ', '
                        + this.state.styleTextRGB.b + ')'
                })
            })
        })
    }

    upDateStyleFeatures = () => {
        this.sourceTempDrawLayer.forEachFeature((feature) => {
            feature.setStyle(this.setStyle(feature))
        });
    }

    updateLabelFeature = (id, label) => {
        this.sourceTempDrawLayer.forEachFeature((feature) => {
            if (feature.ol_uid === id) {
                feature.set("label", label);
                feature.setStyle(this.setStyle(feature))
            }
        });
        this.setState({ features: this.sourceTempDrawLayer.getFeatures() })
    }

    onSubmit = e => {
        e.preventDefault();
        this.context.getLayers().forEach(layer => {
            if (layer.get('title') === "drawLayer") {
                // transfer new feature to def layer
                if (this.state.geometry === undefined) {
                    this.sourceTempDrawLayer.forEachFeature((feature) => {
                        layer.getSource().addFeature(feature);
                    });
                } else {
                    // edit existing feature in the def layer
                    layer.getSource().forEachFeature((oldFeature) => {
                        let newFeature = this.sourceTempDrawLayer.getFeatures()[0];
                        if (oldFeature.ol_uid === newFeature.ol_uid) {
                            oldFeature = newFeature;
                        }
                    });
                }
            }
            if (layer.get('title') === "tempDrawLayer") {
                this.context.removeLayer(layer);
            }
        });
        this.removeDrawInteraction();
        this.props.removeTab(this.props.type);
    };

    /* =========================================================== */
    /* Render                                                      */
    /* =========================================================== */


    render() {
        return (
            <div className="nav-left-l">
                <div className="nav-left__header">
                    <h5>New line</h5>
                    <button className="button--icon" onClick={this.closeLineTab}>
                        <FontAwesomeIcon icon={["fas", "times"]} />
                    </button>
                </div>
                <div className="text-center mt-3"><p>Click on the map to set the line!</p></div>
                <div className="text-center"><p>Save and add new feature for lines with a different style!</p></div>
                <form className="g-form" onSubmit={this.onSubmit}>
                    {/*  */}
                    <ColorLineInput colorName="styleStrokeRGB" color={this.state.styleStrokeRGB} 
                        widthName="styleStrokeWidth" width={this.state.styleStrokeWidth}
                        onChange={this.onChange} onChangeColor={this.onChangeColor} />
                    <TextInput colorName="styleTextRGB" color={this.state.styleTextRGB} 
                        sizeName="styleTextSize" size={this.state.styleTextSize}
                        onChange={this.onChange} onChangeColor={this.onChangeColorTxt}/>
                    {this.state.features.map((feature, i) => {
                        return <Input
                            key={feature.ol_uid}
                            id={feature.ol_uid}
                            type="text"
                            name="label"
                            value={feature.get('label') ? feature.get('label') : ''}
                            placeholder="Label"
                            onChange={this.onChangeLabel}
                        />
                    })}
                    <div>
                        <button type="submit" className="btn btn-primary btn-block m-b">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

EditLine.propTypes = {
    selectedLine: PropTypes.object.isRequired,
    interactions: PropTypes.array.isRequired,
    removeInteraction: PropTypes.func.isRequired,
    addInteraction: PropTypes.func.isRequired,
    removeTab: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    interactions: state.map.interaction,
    selectedLine: state.map.selectedLine
});
const mapDispatchToProps = dispatch => ({
    removeTab: item => dispatch(removeTab(item)),
    removeInteraction: item => dispatch(removeInteraction(item)),
    addInteraction: interaction => dispatch(addInteraction(interaction))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditLine);

EditLine.contextType = MapContext;