import React, { Component } from 'react';
import {SwatchesPicker} from 'react-color';
import Slider from "./Slider";
export default class TextInput extends Component {
    render() {
        return (
            <div className="color__container">
                <div className="color__form">
                    <div className="color__example--container">
                        <div className="color__example" style={{
                            color: "rgb("
                            + this.props.color.r + ", "
                            + this.props.color.g + ", "
                            + this.props.color.b +  ")",
                            "fontSize": this.props.size + "px"
                        }}>
                            Style label
                        </div>
                    </div>
                    <div>
                    <p>Text color:</p>
                        <SwatchesPicker
                        height="540"
                        color={ this.props.color }
                        onChange = {this.props.onChangeColor}/>
                        <p>Size:</p>
                        <Slider label="Size" name={this.props.sizeName} value={this.props.size}
                            max="25" min="10" step="1"
                            onChange={this.props.onChange}
                        />
                    </div>
                    
                </div>
            </div>
        )
    }
}
