import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ViewerPage from "../components/viewer/ViewerPage";

const AppRouter = () => (
    <BrowserRouter>
        <Switch>
            <Route path="/:layer" component={ViewerPage} exact={true} />
            <Route path="/" component={ViewerPage} exact={true} />
        </Switch>
    </BrowserRouter>
);
export default AppRouter;