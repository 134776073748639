import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "./Loader";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {removeTab} from "../../actions/layoutActions";
import { ScaleLine } from "ol/control.js";
import { MapContext } from "../gis/Map";
import { clearLegendElement } from "../../actions/legendActions";
import Ads from "../ads/Ads";
import logo from '../../assets/images/logo.png';

export class TabMenu extends Component {
    closeTab = () => {
        console.log(this.props.name);
        if (this.props.name === "exportTab") {
            // clear redux store for legend to remove all element and allow new legend to be build
            this.props.clearLegendElement();
            // change scalebar
            this.context.getControls().forEach(control => {
            // remove scalebar
            if (control instanceof ScaleLine) {
                this.context.removeControl(control);
                const scaleLineControl = new ScaleLine();
                this.context.addControl(scaleLineControl);
            }
        });
        }
        this.props.removeTab(this.props.name);
    };
    render() {
        return (
            <div>
                {this.props.layout.tab  && (    
                    <div className={this.props.title === "exportTab" ? "tab-menu export-tab" : "tab-menu"} >
                        <div style={{textAlign:"center"}}>
                            <a href="https://infooni.com/maps">
                                <img src={logo}  className="logo"/>
                            </a>
                        </div>
                        <div className="tab-menu__header wim-menu__header">
                            <h5>{this.props.title}</h5>
                            <button className="button--icon" onClick={this.closeTab}>
                                <FontAwesomeIcon icon={["fas", "times"]} />
                            </button>
                        </div>
                        {this.props.isLoading ? <Loader /> : this.props.children}
                        <Ads/>
                    </div>
                )}
            </div>
        );
    }
}

TabMenu.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    layout: PropTypes.object.isRequired,
    removeTab: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.layout.isLoading,
    layout: state.layout
});

const mapDispatchToProps = dispatch => ({
    removeTab: (item) => dispatch(removeTab(item)),
    clearLegendElement: () => dispatch(clearLegendElement())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TabMenu);

TabMenu.contextType = MapContext;