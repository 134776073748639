import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { addTab, removeTab } from "../../actions/layoutActions";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import logo from '../../assets/images/logo.png';

export class Nav extends Component {
    closeTab = () => {
        this.props.removeTab(this.props.name);
    };

    goToLayers = () => {
        this.props.addTab("layersManagement");
    }

    goToPoint = () => {
        this.props.addTab("pointTab");
    };

    goToLine = () => {
        this.props.addTab("lineTab");
    };

    goToPolygon = () => {
        this.props.addTab("polygonTab");
    };

    goToExport = () => {
        this.props.addTab("exportTab");
    };

    goToMapMaker = () => {
        window.open("https://chloroplethmap.infooni.com/", "_blank");
    }

    render() {
        return (
            <div className="nav-left">
                <div style={{textAlign:"center"}}>
                    <a href="https://infooni.com/maps">
                        <img src={logo} className="logo" style={{paddingLeft: "1rem", paddingRight:"1rem"}}/>
                    </a>
                </div>
                <div className="nav-left__header">
                    <h5>Map builder</h5>
                    <button className="button--icon" onClick={this.closeTab}>
                        <FontAwesomeIcon icon={["fas", "times"]} />
                    </button>
                </div>
                <div className="flex" onClick={this.goToLayers}>
                    <FontAwesomeIcon icon={["fas", "layer-group"]} />
                    <div>Layers</div>
                </div>
                <div className="flex" onClick={this.goToPoint}>
                    <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />
                    <div>Draw/Edit Point</div>
                </div>
                <div className="flex" onClick={this.goToLine}>
                    <FontAwesomeIcon icon={["fas", "road"]} />
                    <div>Draw/Edit Line</div>
                </div>
                <div className="flex" onClick={this.goToPolygon}>
                    <FontAwesomeIcon icon={["fas", "draw-polygon"]} />
                    <div>Draw/Edit Polygon</div>
                </div>
                <div className="flex" onClick={this.goToMapMaker}>
                    <FontAwesomeIcon icon={["fas", "fill"]} />
                    <div>Color existing maps (chloropleth maps)</div>
                </div>
                {/* <div className="flex" onClick={this.goToForm}>
                    <FontAwesomeIcon icon={["fas", "directions"]} />
                    <div>Draw/Edit Form</div>
                </div> */}
                <div className="flex" onClick={this.goToExport}>
                    <FontAwesomeIcon icon={["fas", "cloud-download-alt"]} />
                    <div>Export</div>
                </div>
            </div>
        );
    }
}

Nav.propTypes = {
    addTab: PropTypes.func.isRequired,
    removeTab: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    addTab: tab => dispatch(addTab(tab)),
    removeTab: item => dispatch(removeTab(item))
});

export default connect(
    undefined,
    mapDispatchToProps
)(Nav);