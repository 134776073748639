import React, { Component } from 'react'
import { MapContext } from "../gis/Map";

import moment from "moment-timezone";

import Overlay from 'ol/Overlay';

export default class AttributesTimeZone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: []
        }

    }
    close = (e) => {
        this.overlay.setPosition(undefined);
    }

    componentDidMount () { 
        this.overlay = new Overlay({
            element: document.getElementById('popup'),
            autoPan: true,
            autoPanAnimation: {
              duration: 250
            }
          });
        this.context.addOverlay(this.overlay);
        //this.props.loadMap(this.olMap);
        this.context.on('singleclick', (evt) => {
            this.setState({obj: []});
            var pixel = this.context.getEventPixel(evt.originalEvent);
            this.context.forEachFeatureAtPixel(pixel, (f) => {
                let prop = f.values_;
                let yourTime = moment();

                let selectedTime = undefined;

                // check if time zone from raster (countries)
                this.context.forEachLayerAtPixel(pixel, (layer) => {
                    if(["timeZoneUTC"].includes(layer.get("title"))) {
                        var viewResolution = /** @type {number} */ (this.context.getView().getResolution());
                        var url = layer.getSource().getFeatureInfoUrl(
                        evt.coordinate, viewResolution, 'EPSG:3857',
                        {'INFO_FORMAT': 'application/json', 'QUERY_LAYERS': 'worldinmaps:UTC_time_zone'});
                        if (url) {
                        fetch(url)
                            .then( (response) => { return response.text(); })
                            .then( (res) => {
                                res = JSON.parse(res).features[0];
                                if (res === undefined) {
                                    selectedTime = moment().utcOffset(prop.zone);
                                } else {
                                    selectedTime = moment().tz(res.properties.tzid);
                                }

                                let t1 = new Date(yourTime.format("YYYY-MM-DDTHH:mm:ss"));
                                let t2 = new Date(selectedTime.format("YYYY-MM-DDTHH:mm:ss"));
                
                                let dtMillisecond = t2 - t1;
                                let dtHours =  Math.floor((Math.abs(dtMillisecond) / (1000 * 60 * 60)) % 24);
                                let dtMinutes = Math.floor((Math.abs(dtMillisecond) / (1000 * 60)) % 60);
                                dtHours = (dtHours < 10) ? "0" + dtHours : dtHours;
                                dtMinutes = (dtMinutes < 10) ? "0" + dtMinutes : dtMinutes;
                                let dt = dtHours + ":" + dtMinutes;
                                dt = dtMillisecond < 0 ? "-" + dt : "+" + dt;
                
                                let obj = {
                                    timeZone: selectedTime ? selectedTime.format("Z") : undefined,
                                    utc: res ? res.properties.tzid : "/",
                                    date: selectedTime.format("D MMM"),
                                    time: selectedTime.format("HH:mm"),
                                    yourUtc: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    yourDate: yourTime.format("D MMM"),
                                    yourTime: yourTime.format("HH:mm"),
                                    yourOffset: yourTime.format("Z"),
                                    dt: dt
                                };
                
                                let array = this.state.obj;
                                array.push(obj);
                                this.setState({
                                    obj: array
                                })
                                this.overlay.setPosition(evt.coordinate);

                            });
                        }
                    }
                });

            }, {layerFilter: (layer) => {
                return ["timeZone","timeZoneUTC"].includes(layer.get("title"))
            }});
         })
    }
    render() {
        return (
            <div id="popup" className="ol-popup">
                <button id="popup-closer" className="ol-popup-closer" onClick={this.close}></button>
                 {
                        this.state.obj.map((element, index) => {
                            if (index===0) {
                                //var yourTimeZone = -(new Date().getTimezoneOffset() / 60) + "h";
                                
                                return (
                                    <div key={index} className="popup-attribute">
                                        <h3>Selected location</h3>
                                        <ul>
                                            <li>
                                                <b>Time zone name:</b> {element.utc}
                                            </li>
                                            <li>
                                                <b>Date:</b> {element.date}
                                            </li>
                                            <li>
                                                <b>Time:</b> {element.time}
                                            </li>
                                            <li>
                                                <b>Offset:</b> {element.timeZone}
                                            </li>
                                        </ul>
                                        <h3>Your location</h3>
                                        <ul>
                                            <li>
                                                <b>Time zone name:</b> { element.yourUtc }
                                            </li>
                                            <li>
                                                <b>Date:</b> { element.yourDate}
                                            </li>
                                            <li>
                                                <b>Time:</b> { element.yourTime}
                                            </li>
                                            <li>
                                                <b>Offset:</b> {element.yourOffset}
                                            </li>
                                            
                                        </ul>
                                        <h3>
                                            Time difference between you and the 
                                            selected location:
                                        </h3>
                                        <ul>
                                            <li><b>{element.dt}</b></li>
                                        </ul>
                                    </div>
                                    
                                )
                            } else {
                                return undefined;
                            }
                            
                        })
                    }
                </div>
        )
    }
}
AttributesTimeZone.contextType = MapContext;