import { ADD_INTERACTION } from "../actions/typesActions";
import { REMOVE_INTERACTION } from "../actions/typesActions";

import { SELECT_POINT, SELECT_LINE, SELECT_POLYGON } from "../actions/typesActions";

const initialState = {
    interaction: [],
    selectedPoint: {},
    selectedLine: {},
    selectedPolygon: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_INTERACTION:
            return {
                ...state,
                interaction:
                    state.interaction.length > 0
                        ? [...state.interaction, action.interaction]
                        : [action.interaction]
            };
        case REMOVE_INTERACTION:
            return {
                ...state,
                interaction: state.interaction.filter(val => val !== action.index)
            };
        case SELECT_POINT:
            return {
                ...state,
                selectedPoint: action.feature
            };
        case SELECT_LINE:
            return {
                ...state,
                selectedLine: action.feature
            };
        case SELECT_POLYGON:
            return {
                ...state,
                selectedPolygon: action.feature
            };
        default:
            return state;
    }
}
