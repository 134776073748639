import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MapContext } from "../gis/Map";

export default class Legend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLegendOpen: false
        }
    }
    toggleLegend = () => {
        this.setState({isLegendOpen: !this.state.isLegendOpen});
    }
    componentDidMount() {
        this.context.getLayers().forEach(layer => {
            if(layer.get("title") === "densityPopCountry") {
                if(layer.getVisible()) {
                    this.setState({densityPopCountry: true});
                } else {
                    this.setState({densityPopCountry: false});
                }
            }
        });
    }
    render() {
        return (
            <div>
            {!this.state.isLegendOpen && (
                <div className="wim-legend-btn__container ol-unselectable ol-control" onClick={this.toggleLegend}>
                    <button className="wim-legend-btn">
                        <FontAwesomeIcon icon={["fas", "list"]}  />
                    </button>
                </div>
            )}
            {this.state.isLegendOpen && (
                <div className="container wim-legend">
                    <div className="wim-menu__header mt-3">
                        <h2>Legend</h2>
                        <button className="wim-menu-btn" onClick={this.toggleLegend}>
                            <FontAwesomeIcon icon={["fas", "times"]} />
                        </button>
                    </div>

                    {this.props.densityPopCountry && (
                    <div>
                        <h3>Density Population by countries (Pop/km²)</h3>
                        <div className="d-flex align-items-center flex-wrap w-100">
                            <div className="d-flex align-items-center col-6">  
                                <div style={
                                    {
                                        backgroundColor: "#ffffb2",
                                        opacity: 0.7,
                                        border: "1px solid #333333",
                                        width: "35px",
                                        height: "20px",
                                        marginRight: "20px"
                                    }
                                }></div>
                                <div className="mr-3">&lt; 10</div>
                            </div>
                            <div className="d-flex align-items-center col-6">  
                                <div style={
                                    {
                                        backgroundColor: "#ffd76d",
                                        opacity: 0.7,
                                        border: "1px solid #333333",
                                        width: "35px",
                                        height: "20px",
                                        marginRight: "10px"
                                    }
                                }></div>
                                <div className="mr-3">10 - 100</div>
                            </div>
                            <div className="d-flex align-items-center col-6">  
                                <div style={
                                    {
                                        backgroundColor: "#fea649",
                                        opacity: 0.7,
                                        border: "1px solid #333333",
                                        width: "35px",
                                        height: "20px",
                                        marginRight: "10px"
                                    }
                                }></div>
                                <div className="mr-3">100 - 250</div>
                            </div>
                            <div className="d-flex align-items-center col-6">  
                                <div style={
                                    {
                                        backgroundColor: "#e62f21",
                                        opacity: 0.7,
                                        border: "1px solid #333333",
                                        width: "35px",
                                        height: "20px",
                                        marginRight: "10px"
                                    }
                                }></div>
                                <div className="mr-3">250 - 500</div>
                            </div>
                            <div className="d-flex align-items-center col-6">  
                                <div style={
                                    {
                                        backgroundColor: "#bd0026",
                                        opacity: 0.7,
                                        border: "1px solid #333333",
                                        width: "35px",
                                        height: "20px",
                                        marginRight: "10px"
                                    }
                                }></div>
                                <div className="mr-3">&gt; 500</div>
                            </div>
                        </div>
                    </div>
                    )}
                    {this.props.densityPop && (
                        <div>
                            <h3>Density Population (Pop/km²)</h3>
                            <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#ffffff",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">&lt; 20</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#f2fdf2",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">20 - 100</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#d4f6e5",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">100 - 500</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#9ee6e6",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">500 - 1000</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#62c9d9",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">1000 - 2500</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#487ece",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">2500 - 5000</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#62c9d9",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">5000 - 7500</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#3a5ab6",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">7500 - 10000</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#62008e",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">10000 - 12500</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#7e0082",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">12500 - 15000</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#a40048",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">15000 - 17500</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#d90037",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">17500 - 20000</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#ff0000",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">20000 - 30000</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#ff4e00",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">30000 - 40000</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#ffa000",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">40000 - 80000</div>
                                </div>
                                <div className="d-flex align-items-center col-6">  
                                    <div style={
                                        {
                                            backgroundColor: "#ffcd00",
                                            opacity: 1,
                                            border: "1px solid #333333",
                                            width: "35px",
                                            height: "20px",
                                            marginRight: "10px"
                                        }
                                    }></div>
                                    <div className="mr-3">	&gt; 80000</div>
                                </div>
                            </div>
                        </div>
                        )}
                        {(this.props.tectonicBoundaries || this.props.tectonicOrogens || this.props.tectonicPlates )&& (
                            <div>
                                {this.props.tectonicBoundaries && (
                                <div>
                                    <h3>Tectonic plates boundaries</h3>
                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="d-flex align-items-center col-6">  
                                            <div style={
                                                {
                                                    width: "35px",
                                                    height: "20px",
                                                    marginRight: "20px"
                                                }
                                            }>
                                                <div style={
                                                    {
                                                        width: "35px",
                                                        height: "9px",
                                                        borderBottom: "2px solid #e56db3"
                                                    }
                                                }></div>
                                            </div>
                                            <div className="mr-3">Subduction</div>
                                        </div>
                                        <div className="d-flex align-items-center col-6">  
                                            <div style={
                                                {
                                                    width: "35px",
                                                    height: "20px",
                                                    marginRight: "20px"
                                                }
                                            }>
                                                <div style={
                                                    {
                                                        width: "35px",
                                                        height: "9px",
                                                        borderBottom: "2px solid #3cdd7f"
                                                    }
                                                }></div>
                                            </div>
                                            <div className="mr-3">Non-subducting plate boundary</div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                <div>
                                    <p style={
                                        {
                                            fontSize: "0.75rem"
                                        }}>Source: Peter Bird, Department of Earth and Space Sciences, University of California, Los Angeles, CA 90095-1567 Geochemistry Geophysics Geosystems, 4(3), 1027, doi:10.1029/2001GC000252, 2003. 
                                    These data where process to a GIS layer by hugo Ahlenius from <a href="https://nordpil.com">nordpil</a></p>
                                </div>
                            </div>
                        )}
                        {this.props.bathymetry && (
                            <div>
                                <h3>Bathymetry (depth in meters)</h3>
                                <div className="d-flex align-items-center flex-wrap w-100">
                                    <div className="d-flex align-items-center col-6">  
                                        <div style={
                                            {
                                                backgroundColor: "#a9e1d8",
                                                opacity: 1,
                                                border: "1px solid #333333",
                                                width: "35px",
                                                height: "20px",
                                                marginRight: "20px"
                                            }
                                        }></div>
                                        <div className="mr-3">0 - 200 m</div>
                                    </div>
                                    <div className="d-flex align-items-center col-6">  
                                        <div style={
                                            {
                                                backgroundColor: "#81ddd9",
                                                opacity: 1,
                                                border: "1px solid #333333",
                                                width: "35px",
                                                height: "20px",
                                                marginRight: "10px"
                                            }
                                        }></div>
                                        <div className="mr-3">200 - 1000 m</div>
                                    </div>
                                    <div className="d-flex align-items-center col-6">  
                                        <div style={
                                            {
                                                backgroundColor: "#00bacc",
                                                opacity: 1,
                                                border: "1px solid #333333",
                                                width: "35px",
                                                height: "20px",
                                                marginRight: "10px"
                                            }
                                        }></div>
                                        <div className="mr-3">1000 - 2000 m</div>
                                    </div>
                                    <div className="d-flex align-items-center col-6">  
                                        <div style={
                                            {
                                                backgroundColor: "#00accf",
                                                opacity: 1,
                                                border: "1px solid #333333",
                                                width: "35px",
                                                height: "20px",
                                                marginRight: "10px"
                                            }
                                        }></div>
                                        <div className="mr-3">2000 - 3000 m</div>
                                    </div>
                                    <div className="d-flex align-items-center col-6">  
                                        <div style={
                                            {
                                                backgroundColor: "#0090be",
                                                opacity: 1,
                                                border: "1px solid #333333",
                                                width: "35px",
                                                height: "20px",
                                                marginRight: "10px"
                                            }
                                        }></div>
                                        <div className="mr-3">3000 - 4000 m</div>
                                    </div>
                                    <div className="d-flex align-items-center col-6">  
                                        <div style={
                                            {
                                                backgroundColor: "#2270ac",
                                                opacity: 1,
                                                border: "1px solid #333333",
                                                width: "35px",
                                                height: "20px",
                                                marginRight: "10px"
                                            }
                                        }></div>
                                        <div className="mr-3">4000 - 5000 m</div>
                                    </div>
                                    <div className="d-flex align-items-center col-6">  
                                        <div style={
                                            {
                                                backgroundColor: "#245790",
                                                opacity: 1,
                                                border: "1px solid #333333",
                                                width: "35px",
                                                height: "20px",
                                                marginRight: "10px"
                                            }
                                        }></div>
                                        <div className="mr-3">5000 - 6000 m</div>
                                    </div>
                                    <div className="d-flex align-items-center col-6">  
                                        <div style={
                                            {
                                                backgroundColor: "#04477b",
                                                opacity: 1,
                                                border: "1px solid #333333",
                                                width: "35px",
                                                height: "20px",
                                                marginRight: "10px"
                                            }
                                        }></div>
                                        <div className="mr-3">6000 - 7000 m</div>
                                    </div>
                                    <div className="d-flex align-items-center col-6">  
                                        <div style={
                                            {
                                                backgroundColor: "#033d6a",
                                                opacity: 1,
                                                border: "1px solid #333333",
                                                width: "35px",
                                                height: "20px",
                                                marginRight: "10px"
                                            }
                                        }></div>
                                        <div className="mr-3">7000 - 8000 m</div>
                                    </div>
                                    <div className="d-flex align-items-center col-6">  
                                        <div style={
                                            {
                                                backgroundColor: "#043961",
                                                opacity: 1,
                                                border: "1px solid #333333",
                                                width: "35px",
                                                height: "20px",
                                                marginRight: "10px"
                                            }
                                        }></div>
                                        <div className="mr-3">8000 - 9000 m</div>
                                    </div>
                                    <div className="d-flex align-items-center col-6">  
                                        <div style={
                                            {
                                                backgroundColor: "#043459",
                                                opacity: 1,
                                                border: "1px solid #333333",
                                                width: "35px",
                                                height: "20px",
                                                marginRight: "10px"
                                            }
                                        }></div>
                                        <div className="mr-3">9000 - 10000 m</div>
                                    </div>
                                    <div className="d-flex align-items-center col-6">  
                                        <div style={
                                            {
                                                backgroundColor: "#032f50",
                                                opacity: 1,
                                                border: "1px solid #333333",
                                                width: "35px",
                                                height: "20px",
                                                marginRight: "10px"
                                            }
                                        }></div>
                                        <div className="mr-3">&gt; 10000 m</div>
                                    </div>
                                </div>
                            </div>
                            )}
                </div>
            )}
            </div>
        )
    }
}
Legend.contextType = MapContext;