import React, { Component } from 'react'

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { removeTab, addTab } from "../../../actions/layoutActions";
import { selectPolygon } from "../../../actions/mapActions";


export class Polygon extends Component {
    goToPolygonFeature = () => {
        let feature = this.props.feature;
        this.props.selectPolygon(feature);
        this.props.addTab("editPolygonTab");
    };

    render() {
        return (
            <div className="flex" onClick={this.goToPolygonFeature}>
                <div>
                    <div className="" style={{
                        backgroundColor: this.props.fillColor,
                        border: "solid ",
                        borderColor: this.props.strokeColor,
                        borderWidth: this.props.borderWidth + "px",
                        height: "40px",
                        width: "70px"

                    }}>
                    </div>
                </div>
                <div style={{
                    color: this.props.fontColor,
                    font: this.props.font
                }}>{this.props.label}</div>
            </div>
        );
    }
}

Polygon.propTypes = {
    selectPolygon: PropTypes.func.isRequired,
    addTab: PropTypes.func.isRequired,
    removeTab: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    selectPolygon: feature => dispatch(selectPolygon(feature)),
    addTab: tab => dispatch(addTab(tab)),
    removeTab: item => dispatch(removeTab(item))
});

export default connect(
    undefined,
    mapDispatchToProps
)(Polygon);