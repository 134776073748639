import React, { Component } from 'react';
import {SwatchesPicker} from 'react-color';
import Slider from "./Slider";

export default class ColorLineInput extends Component {
    render() {
        return (
            <div className="color__container">
                <div className="color__form">
                     <div className="color__example--container">
                        <div className="color__example--line" style={{
                            borderBottom: "solid",
                            borderColor: "rgb("
                                + this.props.color.r + ", "
                                + this.props.color.g + ", "
                                + this.props.color.b  + ")",
                            "borderWidth": this.props.width + "px",
                            "height": 2 * this.props.width + "px",
                            "width": "70px"

                        }}>
                        </div>
                    </div>
                    <p>Stroke color:</p>
                    <div>
                        <SwatchesPicker
                        height="540"
                        color={ this.props.color }
                        onChange = {this.props.onChangeColor}/>
                        <p>Size:</p>
                        <Slider label="Width" name={this.props.widthName} value={this.props.width}
                            max="25" min="1" step="1"
                            onChange={this.props.onChange}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
