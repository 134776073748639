import React, { Component } from 'react'
import Input from "../layout/form/Input";
import EditPointLegend from "../legend/EditPointLegend";
import EditLineLegend from "../legend/EditLineLegend";
import EditPolygonLegend from "../legend/EditPolygonLegend";

import { addTab, removeTab } from "../../actions/layoutActions";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateLegendElement } from '../../actions/legendActions';

// import Point from "../legend/Point";
// import Line from "../legend/Line";
// import Polygon from "../legend/Polygon";

export class ExportTab extends Component {

    onSubmit = e => {

    }

    onChange = (id, evt, type) => {
        let updatedLegendElement = this.props.legendElements[id];
        let element2Update = {};
        element2Update.id = id;
        element2Update.updatedLegendElement = updatedLegendElement;
        switch (type) {
            case "point":
                element2Update.updatedLegendElement.point.label = evt.currentTarget.value;
                break;
            case "line":
                element2Update.updatedLegendElement.line.label = evt.currentTarget.value;
                break;
            case "polygon":
                element2Update.updatedLegendElement.polygon.label = evt.currentTarget.value;
                break;
            default:
                break;
        }
        this.props.updateLegendElement(element2Update);

    }

    render() {
        let legendElements = this.props.legendElements.map((legendElement, i) => {
            if (legendElement.point) {
                legendElement = legendElement.point;
                return (
                    <EditPointLegend
                        id={i}
                        color={legendElement.color}
                        radius={legendElement.radius}
                        label={legendElement.label}
                        font={legendElement.font}
                        fontColor={legendElement.fontColor}
                        onChange={this.onChange}
                        key={i}
                    />
                )
            } else if (legendElement.line) {
                legendElement = legendElement.line;
                return (<EditLineLegend
                        id={i}
                        color={legendElement.color}
                        width={legendElement.width}
                        label={legendElement.label}
                        font={legendElement.font}
                        fontColor={legendElement.fontColor}
                        onChange={this.onChange}
                        key={i}
                    />);
            } else if (legendElement.polygon) {
                legendElement = legendElement.polygon;
                return (<EditPolygonLegend
                        id={i}
                        fillColor={legendElement.fillColor}
                        strokeColor={legendElement.strokeColor}
                        strokeWidth={legendElement.strokeWidth}
                        label={legendElement.label}
                        font={legendElement.font}
                        fontColor={legendElement.fontColor}
                        onChange={this.onChange}
                        key={i}
                    />);
            }
        });
        return (
            <div className="exportTab">
                <div className="text-center mt-3"><p>Add a title and build the legend of the map.</p></div>
                <form className="g-form" onSubmit={this.onSubmit}>
                    <Input
                        id="1"
                        type="text"
                        name={this.props.name}
                        value={this.props.titleMap}
                        onChange={this.props.onChange}
                        className="form-control"
                        placeholder={this.props.titleMap}
                    />
                    <h1 className="map-title-print text-center">{this.props.titleMap}</h1>
                    <div className="text-center mt-3 add-legend-txt"><p>Configure legend elements</p>
                    {legendElements}
                    </div>
                    <button type="button" className="btn btn-primary btn-block m-b mb-5"
                        onClick={this.props.onDownloadMap}>Download map</button>

                </form>
            </div>
        )
    }
}
ExportTab.propTypes = {
    addTab: PropTypes.func.isRequired,
    removeTab: PropTypes.func.isRequired,
    updateLegendElement: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    legendElements: state.legend.legendElements
});

const mapDispatchToProps = dispatch => ({
    addTab: tab => dispatch(addTab(tab)),
    removeTab: item => dispatch(removeTab(item)),
    updateLegendElement: legendElement => dispatch(updateLegendElement(legendElement))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportTab);
