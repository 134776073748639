import React, { Component } from 'react'

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { selectLegendElement } from "../../actions/legendActions";

export class PointLegend extends Component {


    render() {
        return (
            <tr>
                <td>
                    <div className="color__legend-map--point color__legend-map" style={{
                        backgroundColor: this.props.color,
                        "height": 2 * this.props.radius + "px",
                        "width": 2 * this.props.radius + "px"
                    }}>
                    </div>
                </td>
                <td style={{marginLeft: '15px', overflowWrap: 'normal', maxWidth: '200px'}}>
                    <span style={{font: this.props.font, color:this.props.fontColor}}>{this.props.label}</span>
                </td>
            </tr>
        );
    }
}

PointLegend.propTypes = {
    legendElements: PropTypes.array.isRequired,
    selectLegendElement: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    legendElements: state.legend.legendElements
});

const mapDispatchToProps = dispatch => ({
    selectLegendElement: legendElement => dispatch(selectLegendElement(legendElement))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PointLegend);