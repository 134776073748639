import React from 'react';
import AppRouter from "./routers/AppRouter";
import "normalize.css";
import './App.scss';
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";

function App() {
  const store = configureStore();
  return (
    <div className="App">
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </div>
  );
}

export default App;
