import React, { Component } from 'react'

import { MapContext } from "../gis/Map";
import { addLegendElement } from "../../actions/legendActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ScaleLine } from "ol/control.js";

import TabMenu from "../layout/TabMenu";
import ExportTab from "./ExportTab";

import PointLegend from "../legend/PointLegend";
import LineLegend from "../legend/LineLegend";
import PolygonLegend from "../legend/PolygonLegend";


// usefull link for canvas control and export:
// https://viglino.github.io/ol-ext/examples/canvas/map.canvas.control.html
export class ExportView extends Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     features: []
        // }
        this.state = {
            titleMap: '',
            legendTitle: 'Legend',
        }

        
        
    }

    componentDidMount() {
        this.context.getLayers().forEach(layer => {
            if (layer.get('title') === "drawLayer") {
                let legendElements = this.props.legendElements;
                layer.getSource().forEachFeature(feature => {
                    if (feature.getGeometry().getType() === 'Point') {
                        let pointExist = false;
                        
                        pointExist = legendElements.some(element => {
                            console.log(element);
                            if (element.point) {
                                let point = element.point;

                                if(point.color === feature.style_.image_.fill_.color_
                                    && point.radius === feature.style_.image_.radius_
                                    && point.font === feature.style_.text_.font_
                                    && point.fontColor === feature.style_.text_.fill_.color_) {
                                        return true;
                                }
                            }
                        });
                        if (!pointExist) {
                            let point = { 
                                color:feature.style_.image_.fill_.color_,
                                radius:feature.style_.image_.radius_,
                                label: undefined,
                                font:feature.style_.text_.font_,
                                fontColor:feature.style_.text_.fill_.color_
                                
                            }
                            this.props.addLegendElement({point: point});
                            legendElements.push({point: point});
                        }
                    } else if (feature.getGeometry().getType() === 'LineString') {
                        console.log(feature.style_);
                        let LineExist = false;
                        
                        LineExist = legendElements.some(element => {
                            console.log(feature.style_.stroke_);
                            if (element.line) {
                                let line = element.line;
                                console.log(feature.style_)
                                if(line.color === feature.style_.stroke_.color_
                                    && line.width === feature.style_.stroke_.width_
                                    && line.font === feature.style_.text_.font_
                                    && line.fontColor === feature.style_.text_.fill_.color_) {
                                        return true;
                                }
                            }
                        });
                        if (!LineExist) {
                            let line = { 
                                color:feature.style_.stroke_.color_,
                                width:feature.style_.stroke_.width_,
                                label: undefined,
                                font:feature.style_.text_.font_,
                                fontColor:feature.style_.text_.fill_.color_
                                
                            }
                            this.props.addLegendElement({line: line});
                            legendElements.push({line: line});
                        }
                    } else if (feature.getGeometry().getType() === 'Polygon') {
                        console.log(feature.style_);
                        let polygonExist = false;
                        
                        polygonExist = legendElements.some(element => {
                            console.log(feature.style_.stroke_);
                            if (element.polygon) {
                                let polygon = element.polygon;
                                console.log(feature.style_)
                                if(polygon.fillColor === feature.style_.fill_.color_
                                    && polygon.strokeColor === feature.style_.stroke_.color_
                                    && polygon.strokeWidth === feature.style_.stroke_.width_
                                    && polygon.font === feature.style_.text_.font_
                                    && polygon.fontColor === feature.style_.text_.fill_.color_) {
                                        return true;
                                }
                            }
                        });
                        if (!polygonExist) {
                            let polygon = { 
                                fillColor: feature.style_.fill_.color_,
                                strokeColor: feature.style_.stroke_.color_,
                                strokeWidth: feature.style_.stroke_.width_,
                                label: undefined,
                                font:feature.style_.text_.font_,
                                fontColor:feature.style_.text_.fill_.color_
                                
                            }
                            this.props.addLegendElement({polygon: polygon});
                            legendElements.push({polygon: polygon});
                        }
                    } 
                })

                layer.on('change', this.layerOnChangeEvt);
            }
        });

        // hidde zoom and scale control
        document.getElementsByClassName('ol-zoom')[0].style.visibility = "hidden";

        // change scalebar
        this.context.getControls().forEach(control => {
            // remove scalebar
            if (control instanceof ScaleLine) {
                this.context.removeControl(control);
                let scaleBarControl = new ScaleLine({
                    units: 'metric',
                    bar: true,
                    steps: 4,
                    //text: true,
                    //minWidth: 140
                });
                this.context.addControl(scaleBarControl);
            }
        });

    }

    onChange = e => {
        let key = e.currentTarget.name;
        let value = e.currentTarget.value;
        this.setState({ [key]: value });
    };

    onDownloadMap = () => {
        var mapCanvas = document.createElement('canvas');
        // var img    = mapCanvas.toDataURL("image/png");
        // var link = document.getElementById('image-download');
        // link.href = img;
        // link.click();
        var size = this.context.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        var mapContext = mapCanvas.getContext('2d');
        Array.prototype.forEach.call(
        document.querySelectorAll('.ol-layer canvas'),
        function (canvas) {
            if (canvas.width > 0) {
            var opacity = canvas.parentNode.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            var transform = canvas.style.transform;
            // Get the transform parameters from the style's transform matrix
            var matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(',')
                .map(Number);
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix
            );
            mapContext.drawImage(canvas, 0, 0);
            }
        }
        );
        
        // get all points, lines and polygons from legends
        let points = [];
        let lines = [];
        let polygons = [];
        this.props.legendElements.map((legendElement, i) => {
            console.log(legendElement);
            if (legendElement.point) { 
                points.push(legendElement.point);
            } else if (legendElement.line) {
                lines.push(legendElement.line);
            } else {
                polygons.push(legendElement.polygon);
            }
        });
        // calculate height legend with number of lines labels taken in account

        let titleHeight = 20;
        if(this.state.titleMap) {
            titleHeight = 100;
        }

        //let heightLegend = 80 + (points.length + lines.length + polygons.length)*30 + titleHeight;
        let heightLegend = 80 + titleHeight;
        //for each point
        let heightPoints = 0;
        points.forEach((element) => {
            if (element.label) {
                let length = element.label.length / 200;
                heightPoints += Math.ceil(length) * 50;
            }
        });
        //for each line
        let heightLines = 0;
        lines.forEach((element) => {
            if (element.label) {
                let length = element.label.length / 200;
                heightLines += Math.ceil(length) * 50;
            }
        });
        //for each polygons
        let heightPolygons = 0;
        polygons.forEach((element) => {
            if (element.label) {
                let length = element.label.length / 200;
                heightPolygons += Math.ceil(length) * 50;
            }
        });

        heightLegend += heightPoints + heightLines + heightPolygons;

        // draw white rectangle for legend
        this.createWhiteRectLegend(mapContext, mapCanvas, heightLegend);

        // draw brand (infooni.com)
        this.writeBrandToCanvas(mapContext, mapCanvas);
        // draw scale
        this.writeScaletoCanvas(mapContext, mapCanvas);
        
        // draw legends
        this.writePolygonsToCanvas(mapContext, mapCanvas, polygons, 0);
        this.writeLinesToCanvas(mapContext, mapCanvas, lines, heightPolygons)
        this.writePointsToCanvas(mapContext, mapCanvas, points, (heightLines + heightPolygons));
        this.writeTitleToCanvas(mapContext, mapCanvas, this.state.titleMap, titleHeight + (heightPoints + heightLines + heightPolygons))
        
        if (navigator.msSaveBlob) {
            // link download attribuute does not work on MS browsers
            navigator.msSaveBlob(mapCanvas.msToBlob(), 'map.png');
         } else {
            var link = document.getElementById('image-download');
            link.href = mapCanvas.toDataURL("image/png");
            link.click();
        }
    }

    writeScaletoCanvas = (ctx, canvas) => {
        var olscale = document.querySelector('.ol-scale-bar-inner'); 
        // how big should the scale be (original css-width multiplied)
        var multiplier = 1;
        console.log(olscale);

        var scalewidth = parseInt(olscale.style.width,10)*multiplier;
        var textContent = olscale.textContent;
        var scaleNbrArray = textContent.match(/[0-9]{1,}/g);
        var scale = scaleNbrArray[scaleNbrArray.length-1]
        var scalenumber = parseInt(scale,10)*multiplier;
        var scaleunitArray = textContent.match(/[Aa-zZ]{1,}/g);
        var scaleunit = scaleunitArray[scaleunitArray.length-1]
        
        //Scaleline thicknes
        var line1 = 1;
        //Offset from the left
        var legendRectWidth = 280;
        var x_offset = canvas.width - legendRectWidth / 2 - scalewidth / 2;
        //offset from the bottom
        var y_offset = 50;
        var fontsize1 = 16;
        var font1 = fontsize1 + 'px Arial';


    
        //Scale Text
        ctx.beginPath();
        ctx.textAlign = "center";
        ctx.strokeStyle = "#ffffff";
        ctx.fillStyle = "#000000";
        ctx.lineWidth = 5;
        ctx.font = font1;
        let positionText = canvas.width - legendRectWidth / 2;
        ctx.strokeText([scalenumber + ' ' + scaleunit], positionText, canvas.height - y_offset - fontsize1 / 2);
        ctx.fillText([scalenumber + ' ' + scaleunit], positionText, canvas.height - y_offset - fontsize1 / 2);
    
        //Scale Dimensions
        var xzero = scalewidth + x_offset;
        var yzero = canvas.height - y_offset;
        var xfirst = x_offset + scalewidth * 1 / 4;
        var xsecond = xfirst + scalewidth * 1 / 4;
        var xthird = xsecond + scalewidth * 1 / 4;
        var xfourth = xthird + scalewidth * 1 / 4;
    
        // Stroke
        ctx.beginPath();
        ctx.lineWidth = line1 + 2;
        ctx.strokeStyle = "#000000";
        //ctx.fillStyle = "#ffffff";
        ctx.strokeStyle = "#000000";
        ctx.moveTo(x_offset, yzero);
        ctx.lineTo(xzero + 1, yzero);
        ctx.stroke();
    
        //sections black/white
        ctx.beginPath();
        ctx.lineWidth = line1;
        ctx.strokeStyle = "#000000";
        ctx.moveTo(x_offset, yzero);
        ctx.lineTo(xfirst, yzero);
        ctx.stroke();
    
        ctx.beginPath();
        ctx.lineWidth = line1;
        //ctx.strokeStyle = "#FFFFFF";
        ctx.strokeStyle = "#000000";
        ctx.moveTo(xfirst, yzero);
        ctx.lineTo(xsecond, yzero);
        ctx.stroke();
    
        ctx.beginPath();
        ctx.lineWidth = line1;
        ctx.strokeStyle = "#000000";
        ctx.moveTo(xsecond, yzero);
        ctx.lineTo(xthird, yzero);
        ctx.stroke();
    
        ctx.beginPath();
        ctx.lineWidth = line1;
        //ctx.strokeStyle = "#FFFFFF";
        ctx.strokeStyle = "#000000";
        ctx.moveTo(xthird, yzero);
        ctx.lineTo(xfourth, yzero);
        ctx.stroke();
    } 
    
    writeBrandToCanvas = (ctx, canvas) => {
        var text = "infooni.com"
        var legendRectWidth = 280;
        var x_offset = canvas.width - legendRectWidth + (legendRectWidth - text.length)/2 + text.length / 2;
        //offset from the bottom
        var y_offset = 20;
        var fontsize1 = 13;
        var font1 = fontsize1 + 'px Arial';

        // write text
        ctx.beginPath();
        ctx.textAlign = "center";
        ctx.fillStyle = "#676a6c";
        ctx.font = font1;
        ctx.fillText(text, x_offset + fontsize1 / 2, canvas.height - y_offset - fontsize1 / 2);
    };

    writeTitleToCanvas = (ctx, canvas, title, startY) => {
        var legendRectWidth = 280;
        var x_offset = canvas.width - legendRectWidth + (legendRectWidth - title.length) / 2 + title.length / 2;
        //offset from the bottom
        var y_offset = startY + 20;
        var fontsize = 16;
        var font = 'bold ' + fontsize + 'px Arial';

        // write text
        ctx.beginPath();
        ctx.textAlign = "center";
        ctx.fillStyle = "#000000";
        ctx.font = font;
        let x = x_offset + fontsize / 2;
        let y = canvas.height - y_offset - fontsize / 2;
        var lineHeight = fontsize*1.1;
        this.wrapText (ctx, title, x, y, 250, lineHeight);

    }

    writePointsToCanvas = (ctx, canvas, points, startY) => {
        //Offset from the left
        var x_offset = canvas.width - 250;
        //offset from the bottom
        var y_offset = canvas.height - 80 - startY;

        // for each point
        points.forEach((element) => {
            if (element.label) {
                y_offset = y_offset - 30;
                // draw circle
                ctx.fillStyle = element.color;
                let radius = element.radius;
                ctx.beginPath();
                ctx.arc(x_offset,y_offset,radius,0,2*Math.PI);
                ctx.fill();
                
                // move for label
                let x_offset_label = x_offset + 40; // 15 to center label with line and polygon
                // draw label
                let label = element.label;
                let font = element.font;
                let fontColor = element.fontColor;
                ctx.beginPath();
                ctx.textAlign = "left";
                ctx.fillStyle = fontColor;
                ctx.font = font;
                let sizeFontRegex =  font.match(/^\d+/g);
                var lineHeight = sizeFontRegex[0]*1.1;
                this.wrapText(ctx, label, x_offset_label, y_offset + sizeFontRegex[0] / 4, 200, lineHeight);
            }
            
        })
    }

    writeLinesToCanvas = (ctx, canvas, lines,startY) => {
        //Offset from the left
        var x_offset = canvas.width - 250 -15; // 15 to get half width to center on point
        //offset from the bottom
        var y_offset = canvas.height - 80 - startY;

        // for each lines
        lines.forEach((element) => {
            if (element.label) {
                console.log(element);
                y_offset = y_offset - 30;

                // config
                ctx.strokeStyle = element.color;
                ctx.lineWidth = element.width;
                const lineLength = 30;

                // draw line
                ctx.beginPath();
                ctx.moveTo(x_offset, y_offset);
                ctx.lineTo(x_offset + 30, y_offset);
                ctx.stroke();
                
                // move for label
                let x_offset_label = x_offset + lineLength/2 + 40;

                // draw label
                let label = element.label;
                let font = element.font;
                let fontColor = element.fontColor;

                ctx.beginPath();
                ctx.textAlign = "left";
                ctx.fillStyle = fontColor;
                ctx.font = font;
                let sizeFontRegex =  font.match(/^\d+/g);
                var lineHeight = sizeFontRegex[0]*1.1;
                this.wrapText(ctx, label, x_offset_label, y_offset + sizeFontRegex[0] / 4, 200, lineHeight);
            }
            
        })
    }

    writePolygonsToCanvas = (ctx, canvas, polygons,startY) => {
        console.log(polygons);
        //Offset from the left
        var x_offset = canvas.width - 250 - 15;  // 15 to get half width to center on point
        //offset from the bottom
        var y_offset = canvas.height - 80 - startY;

        // for each lines
        polygons.forEach((element) => {
            if (element.label) {
                y_offset = y_offset - 30;

                // config
                ctx.fillStyle = element.fillColor;
                ctx.strokeStyle = element.strokeColor;
                ctx.lineWidth = element.strokeWidth;
                const width = 30;
                const height = 20;

                // draw rectangle
                ctx.beginPath();
                ctx.rect(x_offset, y_offset, width, height);
                ctx.stroke();
                ctx.fill();

                // move for label
                let x_offset_label = x_offset + 15 + 40;

                // draw label
                let label = element.label;
                let font = element.font;
                let fontColor = element.fontColor;

                ctx.beginPath();
                ctx.textAlign = "left";
                ctx.fillStyle = fontColor;
                ctx.font = font;
                let sizeFontRegex =  font.match(/^\d+/g);
                var lineHeight = sizeFontRegex[0]*1.286;
                this.wrapText(ctx, label, x_offset_label, y_offset + sizeFontRegex[0] / 4, 200, lineHeight);
            }
            
        })
    }

    createWhiteRectLegend = (ctx, canvas, height) => {
        var width = 250 + 30;
        //Offset from the left
        var x_offset = canvas.width - width;

        //offset from the bottom
        var y_offset = canvas.height - height;

        // config
        ctx.fillStyle = "#ffffff";
        ctx.strokeStyle = "#000000";
        ctx.lineWidth = 2;

        // draw rectangle
        ctx.beginPath();
        ctx.rect(x_offset, y_offset, width, height);
        ctx.stroke();
        ctx.fill();
    }

    // wrapText = (ctx, text, x, y, maxWidth, fontSize, font) => {
    //     var firstY=y;
    //     var words = text.split(' ');
    //     var line = '';
    //     var lineHeight=fontSize*1.286; // a good approx for 10-18px sizes
      
    //     ctx.font=font;
    //     ctx.textBaseline='top';
      
    //     for(var n = 0; n < words.length; n++) {
    //       var testLine = line + words[n] + ' ';
    //       var metrics = ctx.measureText(testLine);
    //       var testWidth = metrics.width;
    //       if(testWidth > maxWidth) {
    //         ctx.fillText(line, x, y);
    //         if(n<words.length-1){
    //             line = words[n] + ' ';
    //             y += lineHeight;
    //         }
    //       }
    //       else {
    //         line = testLine;
    //       }
    //     }
    //     ctx.fillText(line, x, y);
    //   }
    wrapText = (context, text, x, y, maxWidth, lineHeight) => {
        var words = text.split(' ');
        var line = '';

        for(var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = context.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, y);
            line = words[n] + ' ';
            y += lineHeight;
          }
          else {
            line = testLine;
          }
        }
        context.fillText(line, x, y);
    }

    render() {
        let legend = this.props.legendElements.map((legendElement, i) => {
            console.log(legendElement);
            if (legendElement.point && legendElement.point.label) {
                legendElement = legendElement.point;
                return (
                    <PointLegend
                        id={i}
                        color={legendElement.color}
                        radius={legendElement.radius}
                        label={legendElement.label}
                        font={legendElement.font}
                        fontColor={legendElement.fontColor}
                        key={i}
                    />
                )
            } else if (legendElement.line && legendElement.line.label) {
                legendElement = legendElement.line;
                return (
                    <LineLegend
                        id={i}
                        color={legendElement.color}
                        width={legendElement.width}
                        label={legendElement.label}
                        font={legendElement.font}
                        fontColor={legendElement.fontColor}
                        key={i}
                    />
                )
            } else if (legendElement.polygon && legendElement.polygon.label) {
                legendElement = legendElement.polygon;
                return (
                    <PolygonLegend
                        id={i}
                        fillColor={legendElement.fillColor}
                        strokeColor={legendElement.strokeColor}
                        strokeWidth={legendElement.strokeWidth}
                        label={legendElement.label}
                        font={legendElement.font}
                        fontColor={legendElement.fontColor}
                        key={i}
                    />
                )
            }
        });
        return (
            <div>
                <TabMenu key={this.props.key} title={this.props.title} name={this.props.name} >
                    <ExportTab
                        history={this.props.history}
                        titleMap={this.state.titleMap}
                        name="titleMap"
                        legendValue={this.state.legendTitle}
                        legendName="legendTitle"
                        onChange={this.onChange} 
                        onDownloadMap = {this.onDownloadMap}/>
                </TabMenu>
                <div class="legend">
                    {this.state.titleMap && (
                        <h2 class="titleLegend">{this.state.titleMap}</h2>
                    )}
                    <table>
                        {legend}
                    </table>
                    <p class="wim-brand">infooni.com</p>
                </div>
                <a id="image-download" download="map.png"></a>
            </div>
        )
    }
}
ExportView.propTypes = {

    addLegendElement: PropTypes.func.isRequired,
    //   clients: PropTypes.array.isRequired,
    // addTab: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    legendElements: state.legend.legendElements
});

const mapDispatchToProps = dispatch => ({
    addLegendElement: element => dispatch(addLegendElement(element))
    // addTab: tab => dispatch(addTab(tab))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportView);

ExportView.contextType = MapContext;
