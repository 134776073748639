import React, { Component } from 'react'

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { removeTab, addTab } from "../../../actions/layoutActions";
import { selectPoint } from "../../../actions/mapActions";


export class Point extends Component {
    goToPointFeature = () => {
        let feature = this.props.feature;
        this.props.selectPoint(feature);
        this.props.addTab("editPointTab");
    };

    render() {
        return (
            <div className="flex" onClick={this.goToPointFeature}>
                <div>
                    <div className="color__example" style={{
                        backgroundColor: this.props.color,
                        "height": 2 * this.props.radius + "px",
                        "width": 2 * this.props.radius + "px"
                    }}>
                    </div>
                </div>
                <div style={{
                    color: this.props.fontColor,
                    font: this.props.font
                }}>{this.props.label}</div>
            </div>
        );
    }
}

Point.propTypes = {
    selectPoint: PropTypes.func.isRequired,
    addTab: PropTypes.func.isRequired,
    removeTab: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    selectPoint: feature => dispatch(selectPoint(feature)),
    addTab: tab => dispatch(addTab(tab)),
    removeTab: item => dispatch(removeTab(item))
});

export default connect(
    undefined,
    mapDispatchToProps
)(Point);
