import React, { Component } from 'react'
import { MapContext } from "../gis/Map";

import Overlay from 'ol/Overlay';

export default class Attributes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: []
        }

    }
    close = (e) => {
        this.overlay.setPosition(undefined);
    }

    componentDidMount () { 
        this.overlay = new Overlay({
            element: document.getElementById('popup'),
            autoPan: true,
            autoPanAnimation: {
              duration: 250
            }
          });
        this.context.addOverlay(this.overlay);
        //this.props.loadMap(this.olMap);
        this.context.on('singleclick', (evt) => {
            this.setState({obj: []});
            var pixel = this.context.getEventPixel(evt.originalEvent);
            this.context.forEachLayerAtPixel(pixel, (layer) => {
                if(["densityPopCountry"].includes(layer.get("title"))) {
                    var viewResolution = /** @type {number} */ (this.context.getView().getResolution());
                    var url = layer.getSource().getFeatureInfoUrl(
                    evt.coordinate, viewResolution, 'EPSG:3857',
                    {'INFO_FORMAT': 'application/json', 'QUERY_LAYERS': 'worldinmaps:density_pop'});
                    if (url) {
                    fetch(url)
                        .then( (response) => { return response.text(); })
                        .then( (res) => {
                            res = JSON.parse(res).features[0];
                            if(res != undefined) {
                                if (res.properties) {
                    
                                    let obj = {
                                        country: res.properties.country_name,
                                        population: res.properties.pop_2020,
                                        surface: res.properties.surf_2020,
                                        densityPop: res.properties.density_2020,
                                        percPopEarth: res.properties.perc_pop_earth
                                    };
                                    let array = this.state.obj;
                                    array.push(obj);
                                    this.setState({
                                        obj: array
                                    })
                                    this.overlay.setPosition(evt.coordinate);
                                };
                            }
                        });
                    }

                }
            });
         })
    }
    render() {
        return (
            <div id="popup" className="ol-popup">
                <button id="popup-closer" className="ol-popup-closer" onClick={this.close}></button>
                 {
                        this.state.obj.map((element, index) => {
                            return (
                                <div key={index} className="popup-attribute">
                                    <h3>{element.country}</h3>
                                    <ul>
                                        <li><b>Population:</b> {element.population}</li>
                                        <li><b>Surface:</b> {element.surface} km²</li>
                                        <li><b>Density of population:</b> {element.densityPop} inh/km²</li>
                                        <li><b>Percentage of the earth population:</b> {element.percPopEarth}%</li>
                                    </ul>
                                </div>
                            )
                        })
                    }
                </div>
        )
    }
}
Attributes.contextType = MapContext;