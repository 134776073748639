import React, { Component } from 'react';
import {SwatchesPicker} from 'react-color';
import Slider from "./Slider";

export default class ColorInput extends Component {
    render() {
        return (
            <div className="color__container">
                <div className="color__form">
                    <div className="color__example--container">
                        <div className="color__example--polygon" style={{
                            backgroundColor: "rgba("
                                + this.props.colorFill.r + ", "
                                + this.props.colorFill.g + ", "
                                + this.props.colorFill.b + ", "
                                + this.props.opacity + ")",
                            border: "solid",
                            borderColor: "rgb("
                                + this.props.colorStroke.r + ", "
                                + this.props.colorStroke.g + ", "
                                + this.props.colorStroke.b + ")",
                            "borderWidth": this.props.width + "px",
                            "height": "40px",
                            "width": "70px"

                        }}>
                        </div>
                    </div>
                    <p>Stroke color:</p>
                    <div>
                        <SwatchesPicker
                        height="540"
                        color={ this.props.colorStroke }
                        onChange = {this.props.onChangeStrokeColor}/>
                        <p>Size:</p>
                        <Slider label="Width" name={this.props.widthName} value={this.props.width}
                            max="25" min="1" step="1"
                            onChange={this.props.onChange}
                        />
                        <p>Fill color:</p>
                        <SwatchesPicker
                            height="540"
                            color={ this.props.colorFill }
                            onChange = {this.props.onChangeColorFill}/>
                        <Slider label="Opacity" name={this.props.opacityName} value={this.props.opacity}
                            max="1" min="0" step="0.1"
                            onChange={this.props.onChange}
                        />
                    </div>
                    
                </div>
            </div>
        )
    }
}
