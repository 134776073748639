import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MapContext } from "../../gis/Map";

import Point from "./Point";

//import Point from "./Point";
import PropTypes from "prop-types";
import { addTab } from "../../../actions/layoutActions";
import { connect } from "react-redux";

export class ListPoints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            features: []
        }
    }

    componentDidMount() {
        this.context.getLayers().forEach(layer => {
            if (layer.get('title') === "drawLayer") {
                let features = []
                layer.getSource().forEachFeature(feature => {
                    if (feature.getGeometry().getType() === 'Point') {
                        features.push(feature);
                    }
                })
                this.setState({ features: features });

                layer.on('change', this.layerOnChangeEvt);
            }
        });
    }

    layerOnChangeEvt = (evt) => {
        let features = []
        let source = evt.target.getSource();
        source.forEachFeature(feature => {
            if (feature.getGeometry().getType() === 'Point') {
                features.push(feature);
            }
        })
        this.setState({ features: features });

    }

    componentWillUnmount() {
        this.context.getLayers().forEach(layer => {
            if (layer.get('title') === "drawLayer") {
                layer.un('change', this.layerOnChangeEvt);
            }
        });
    }

    goToNewPoint = () => {
        this.props.addTab("newPointTab");
    };

    render() {
        return (
            <div className="list">
                <form className="m-t g-form no-p-l">
                    <div className="form-group search__container">
                        <div className="g-form__add-item" onClick={this.goToNewPoint}>
                            <FontAwesomeIcon icon={["fas", "plus"]} size="lg" />
                        </div>
                    </div>
                </form>
                {
                    this.state.features.length > 0 &&
                    (<div className="text-center"><p>Click on the label for edition.</p></div>)
                }
                {
                    this.state.features.length > 0 && this.state.features.map(feature => {
                        let label = '';
                        if (feature.get('label')) {
                            label = feature.get('label');
                        } else {
                            label = "No label";
                        }
                        return (
                            <Point
                                id={feature.ol_uid}
                                feature={feature}
                                color={feature.style_.image_.fill_.color_}
                                radius={feature.style_.image_.radius_}
                                label={label}
                                font={feature.style_.text_.font_}
                                fontColor={feature.style_.text_.fill_.color_}
                                key={feature.ol_uid}
                            />
                        );
                    })
                }
                {
                    this.state.features.length === 0 &&
                    (<div className="text-center"><p>No point feature. Add some by clicking on the plus icon.</p></div>)
                }
            </div>
        );
    }
}
ListPoints.propTypes = {
    //   clients: PropTypes.array.isRequired,
    addTab: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    //   clients: state.clients
});

const mapDispatchToProps = dispatch => ({
    addTab: tab => dispatch(addTab(tab))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListPoints);

ListPoints.contextType = MapContext;