// layout
export const ADD_TAB = "ADD_TAB";
export const REMOVE_TAB = "REMOVE_TAB";
export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";

// map event and config
export const ADD_INTERACTION = "ADD_INTERACTION";
export const REMOVE_INTERACTION = "REMOVE_INTERACTION";

// features
export const SELECT_POINT = "SELECT_POINT";
export const SELECT_LINE = "SELECT_LINE";
export const SELECT_POLYGON = "SELECT_POLYGON";

// legend elements
export const ADD_LEGEND_ELEMENT = "ADD_LEGEND_ELEMENT";
export const SELECT_LEGEND_ELEMENT = "SELECT_LEGEND_ELEMENT";
export const UPDATE_LEGEND_ELEMENT = "UPDATE_LEGEND_ELEMENT";
export const CLEAR_LEGEND_ELEMENT = "CLEAR_LEGEND_ELEMENT";