import React, { Component } from 'react'
import { MapContext } from "../gis/Map";

import moment from "moment-timezone";

import Overlay from 'ol/Overlay';


export default class AttributesEarthquakes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: []
        }

    }
    close = (e) => {
        this.overlay.setPosition(undefined);
    }

    componentDidMount () { 
        this.overlay = new Overlay({
            element: document.getElementById('popup-earthquakes'),
            autoPan: true,
            autoPanAnimation: {
              duration: 250
            }
          });
        this.context.addOverlay(this.overlay);
        //this.props.loadMap(this.olMap);
        this.context.on('singleclick', (evt) => {
            this.setState({obj: []});
            var pixel = this.context.getEventPixel(evt.originalEvent);
            var selectFeature = false;
            this.context.forEachFeatureAtPixel(pixel, (f) => {
                if (!selectFeature) {
                    selectFeature = true;
                    const url = f.get("detail");
                    if (url) {
                        fetch(url)
                        .then((resp) => resp.json())
                        .then(details => {
                            console.log(details);
                            // get and format time
                            const time = details.properties.time;
                            const dt = Date.now() - time;
                            let dtStrg = (dt / 1000) / 60 // get minutes

                            if (dtStrg > (60*24*7)) {
                                dtStrg = Math.floor(dtStrg / (60*24*7));
                                console.log(dtStrg);
                                if (dtStrg > 1) {
                                    dtStrg += " weeks ago";
                                } else {
                                    dtStrg += " week ago";
                                }
                            } else if (dtStrg > (60*24)) {
                                dtStrg = Math.floor(dtStrg / (60*24));
                                if (dtStrg > 1) {
                                    dtStrg += " days ago";
                                } else {
                                    dtStrg += " day ago";
                                }
                            } else if (dtStrg > 60) {
                                dtStrg = Math.floor(dtStrg / 60);
                                if (dtStrg > 1) {
                                    dtStrg += " hours ago";
                                } else {
                                    dtStrg += " hour ago";
                                }
                            }else {
                                dtStrg = Math.floor(dtStrg);
                                if (dtStrg > 1) {
                                    dtStrg += " minutes ago";
                                } else {
                                    dtStrg += " minute ago";
                                }
                            }
                            // get and format other info
                            const magnitude = Math.round(details.properties.mag * 10) / 10;
                            const color = ["#afea35", "#afea35", "#d8d734", "#f3af30", "#f8912c", "#f57629", "#f46426", "#f24624", "#d33a1d", "#b43017", "#b43017", "#b43017", "#b43017"]
                            const magnitudeColor = color[Math.floor(magnitude)];
                            let depth = details.properties.products.origin[0].properties.depth;
                            depth = Math.round(depth) + " km";
                            const place = details.properties.place;
                            const title = details.properties.title;
                            let obj = {
                                title: title,
                                time: dtStrg, 
                                magnitude: magnitude,
                                magnitudeColor: magnitudeColor,
                                depth: depth,
                                place: place
                            };
            
                            let array = this.state.obj;
                            array.push(obj);
                            this.setState({
                                obj: array
                            })
                            this.overlay.setPosition(evt.coordinate);
                        })
                    }
                }
            }, {layerFilter: (layer) => {
                return layer.get('title') === 'earthquakes';
            }});
         })
    }
    render() {
        return (
            <div id="popup-earthquakes" className="ol-popup">
                <button id="popup-closer" className="ol-popup-closer" onClick={this.close}></button>
                 {
                        this.state.obj.map((element, index) => {
                            if (index===0) {
                                //var yourTimeZone = -(new Date().getTimezoneOffset() / 60) + "h";
                                
                                return (
                                    <div key={index} className="popup-attribute">
                                        <h3>{element.title}</h3>
                                        <ul>
                                            <li>
                                                <i>About {element.time}</i>
                                            </li>
                                            <li>
                                                <b>Magnitude:</b> <span style={{color: element.magnitudeColor}}>{element.magnitude}</span>
                                            </li>
                                            <li>
                                                <b>Depth:</b> {element.depth}
                                            </li>
                                            <li>
                                                <b>Place:</b> {element.place}
                                            </li>
                                        </ul>
                                    </div>
                                    
                                )
                            } else {
                                return undefined;
                            }
                            
                        })
                    }
                </div>
        )
    }
}
AttributesEarthquakes.contextType = MapContext;