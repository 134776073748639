import React, { Component } from 'react'

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { selectLegendElement } from "../../actions/legendActions";

export class EditLineLegend extends Component {


    render() {
        console.log(this.props.width);
        return (
            <div className="flex-legend">
                <div>
                    <div className="color__legend--line" style={{
                        borderBottom: "solid",
                        borderColor: this.props.color,
                        borderWidth: this.props.width + "px",
                        //height: 2 * this.props.width + "px",
                        width: "70px"

                    }}>
                    </div>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        name={"input-label-" + this.props.id}
                        value={this.props.label || ''} 
                        onChange={(evt) => this.props.onChange(this.props.id, evt, "line")}
                        className="form-control"
                        placeholder="Label"
                    />
                </div>
            </div>
        );
    }
}

EditLineLegend.propTypes = {
    legendElements: PropTypes.array.isRequired,
    selectLegendElement: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    legendElements: state.legend.legendElements
});

const mapDispatchToProps = dispatch => ({
    selectLegendElement: legendElement => dispatch(selectLegendElement(legendElement))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditLineLegend);