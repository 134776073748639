import React, { Component } from "react";

export default class ModernInput extends Component {
    render() {
        return (
            <div className="form-group">
                <input
                    id={this.props.id}
                    type={this.props.type}
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    className="form-control"
                    placeholder={this.props.placeholder}
                />
            </div>
        );
    }
}