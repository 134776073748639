// GET_ATTRIBUTES
import { ADD_LEGEND_ELEMENT, SELECT_LEGEND_ELEMENT, UPDATE_LEGEND_ELEMENT, CLEAR_LEGEND_ELEMENT } from "./typesActions";

export const addLegendElement = (legendElement = {}) => {
    return dispatch => {
        return dispatch({
            type: ADD_LEGEND_ELEMENT,
            legendElement
        });
    };
};

export const selectLegendElement = legendElement => {
    return {
        type: SELECT_LEGEND_ELEMENT,
        legendElement
    }
}

export const updateLegendElement = legendElement => {
    return {
        type: UPDATE_LEGEND_ELEMENT,
        legendElement
    }
}

export const clearLegendElement = () => {
    return {
        type: CLEAR_LEGEND_ELEMENT
    }
}