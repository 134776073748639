// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import {
    faLayerGroup,
    faMapMarkerAlt,
    faRoad,
    faDrawPolygon,
    faCloudDownloadAlt,
    faBars,
    faPlus,
    faTimes,
    faList,
    faFill
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faLayerGroup,
    faMapMarkerAlt,
    faRoad,
    faDrawPolygon,
    faCloudDownloadAlt,
    faBars,
    faPlus,
    faTimes,
    faList,
    faFill
    // more icons go here
);