import React, { Component } from 'react'

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { selectLegendElement } from "../../actions/legendActions";

export class EditPointLegend extends Component {


    render() {
        return (
            <div className="flex-legend">
                <div>
                    <div className="color__legend" style={{
                        backgroundColor: this.props.color,
                        "height": 2 * this.props.radius + "px",
                        "width": 2 * this.props.radius + "px"
                    }}>
                    </div>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        name={"input-label-" + this.props.id}
                        value={this.props.label || ''} 
                        onChange={(evt) => this.props.onChange(this.props.id, evt, "point")}
                        className="form-control"
                        placeholder="Label"
                    />
                </div>
            </div>
        );
    }
}

EditPointLegend.propTypes = {
    legendElements: PropTypes.array.isRequired,
    selectLegendElement: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    legendElements: state.legend.legendElements
});

const mapDispatchToProps = dispatch => ({
    selectLegendElement: legendElement => dispatch(selectLegendElement(legendElement))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditPointLegend);