import React, { Component } from 'react'

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { removeTab, addTab } from "../../../actions/layoutActions";
import { selectLine } from "../../../actions/mapActions";


export class Line extends Component {
    goToLineFeature = () => {
        let feature = this.props.feature;
        this.props.selectLine(feature);
        this.props.addTab("editLineTab");
    };

    render() {
        return (
            <div className="flex" onClick={this.goToLineFeature}>
                <div>
                    <div className="" style={{
                        borderBottom: "solid",
                        borderColor: this.props.color,
                        borderWidth: this.props.width + "px",
                        //height: 2 * this.props.width + "px",
                        width: "70px"

                    }}>
                    </div>
                </div>
                <div style={{
                    color: this.props.fontColor,
                    font: this.props.font
                }}>{this.props.label}</div>
            </div>
        );
    }
}

Line.propTypes = {
    selectLine: PropTypes.func.isRequired,
    addTab: PropTypes.func.isRequired,
    removeTab: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    selectLine: feature => dispatch(selectLine(feature)),
    addTab: tab => dispatch(addTab(tab)),
    removeTab: item => dispatch(removeTab(item))
});

export default connect(
    undefined,
    mapDispatchToProps
)(Line);