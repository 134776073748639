import React, { Component } from 'react'

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { selectLegendElement } from "../../actions/legendActions";

export class PolygonLegend extends Component {


    render() {
        return (
            <tr>
                <td>
                    <div className="color__legend-map" style={{
                        backgroundColor: this.props.fillColor,
                        border: "solid ",
                        borderColor: this.props.strokeColor,
                        borderWidth: this.props.strokeWidth + "px",
                        height: "40px",
                        width: "70px"

                    }}>
                    </div>
                </td>
                <td style={{marginLeft: '15px', overflowWrap: 'normal', maxWidth: '200px'}}>
                    <span style={{font: this.props.font, color:this.props.fontColor}}>{this.props.label}</span>
                </td>
            </tr>
        );
    }
}

PolygonLegend.propTypes = {
    legendElements: PropTypes.array.isRequired,
    selectLegendElement: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    legendElements: state.legend.legendElements
});

const mapDispatchToProps = dispatch => ({
    selectLegendElement: legendElement => dispatch(selectLegendElement(legendElement))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PolygonLegend);