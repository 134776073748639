// PROD
// import React, { Component } from 'react'
// import OlMap from "ol/Map";
// import OlView from "ol/View";

// import ImageLayer from "ol/layer/Image.js";
// import ImageWMS from "ol/source/ImageWMS.js";

// import VectorSource from "ol/source/Vector.js";
// import { Vector as VectorLayer } from "ol/layer.js";

// import GeoJSON from "ol/format/GeoJSON";
// import { bbox as bboxStrategy } from "ol/loadingstrategy.js";
// import { ScaleLine, Zoom } from "ol/control.js";

// import { Stroke, Style, Fill, Circle } from "ol/style.js";
// import TileLayer from "ol/layer/Tile";
// import TileWMS from 'ol/source/TileWMS';
// import { OSM, XYZ } from "ol/source";

// export const MapContext = React.createContext(undefined);
// export default class Map extends Component {
//     constructor(props) {
//         super(props);
//         // const
//         const urlServer = "https://app-geoserver.worldinmaps.com/geoserver";
//         //const urlServer = "http://51.68.33.200/geoserver";

//         // basic control
//         const scaleLineControl = new ScaleLine();
//         const zoomBtnControl = new Zoom();

//         // create array with the layers
//         const layersArray = [];

//         // init openlayer map object
//         const osm = new TileLayer({
//             title: "osm",
//             source: new OSM(),
//             visible: false
//         });

//         // init free aerial map
//         const aerialMap = new TileLayer({
//             title: "satellite",
//             source: new XYZ({
//             attributions: ['Powered by Esri',
//                         'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
//             attributionsCollapsible: false,
//             url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
//             maxZoom: 23
//         }),
//         visible: false
//         });

//         const countries = new ImageLayer({
//               title: "countries",
//               source: new ImageWMS({
//                 url: urlServer + "/DBworldinmaps/wms?",
//                 params: {
//                   service: "WMS",
//                   layers: "DBworldinmaps:countries"
//                 }
//               })
//             });

//             const tropicsCirclesEquatorDL = new ImageLayer({
//               title: "tropicsCirclesEquatorDL",
//               source: new ImageWMS({
//                 url: urlServer + "/basemap/wms",
//                 params: {
//                   service: "WMS",
//                   layers: "basemap:geographic_lines"
//                 }
//               }),
//               visible: false
//             });

//             const graticules20 = new ImageLayer({
//               title: "graticules20",
//               source: new ImageWMS({
//                 url: urlServer + "/basemap/wms",
//                 params: {
//                   service: "WMS",
//                   layers: "basemap:graticules_20"
//                 }
//               }),
//               visible: false
//             });

//             const densityPopCountry = new ImageLayer({
//                 title: "densityPopCountry",
//                 source: new ImageWMS({
//                   url: urlServer + "/DBworldinmaps/wms",
//                   params: {
//                     service: "WMS",
//                     layers: "DBworldinmaps:density_pop"
//                   }
//                 }),
//                 visible: false
//               });

//               const densityPop = new TileLayer({
//                 title: "densityPop",
//                 source: new TileWMS({
//                   url: urlServer + "/people/wms",
//                   params: {
//                     service: "WMS",
//                     layers: "people:density-pop-sat-2015-tiles"
//                   }
//                 }),
//                 visible: false
//               });

//               let timeZoneVectorSource = new VectorSource({
//                 format: new GeoJSON(),
//                 url: function(extent) {
//                   return urlServer + "/DBworldinmaps/ows?" +
//                   "service=WFS&version=1.1.0&request=GetFeature&" +
//                   "typename=DBworldinmaps:world_time_zone&outputFormat=application/json&" +
//                   "srsname=EPSG:3857&bbox="+ extent.join(",") + "," + "EPSG:3857";
//                 },
//                 strategy: bboxStrategy
//               });

//               let timeZone = new VectorLayer({
//                 title: "timeZone",
//                 source: timeZoneVectorSource,
//                 style: new Style({
//                   stroke: new Stroke({
//                     color: "rgba(0,135,213, 0.5)",
//                     width: 1
//                   }),
//                   fill: new Fill({color: 'rgba(0,0,0,0)'})
//                 }),
//                 visible: false
//               });

//               let UTCtimeZone = new TileLayer({
//                 title: "timeZoneUTC",
//                 source: new TileWMS({
//                   url: urlServer + "/DBworldinmaps/wms",
//                   params: {
//                     service: "WMS",
//                     layers: "DBworldinmaps:UTC_time_zone"
//                   }
//                 }),
//                 visible: false
//               });


//         const tectonicBoundaries = new ImageLayer({
//           title: "tectonicBoundaries",
//           source: new ImageWMS({
//             url: urlServer +"/DBworldinmaps/wms?",
//             params: {
//               service: "WMS",
//               layers: "DBworldinmaps:tectonic_boundaries"
//             }
//           }),
//           visible: false
//         });
//         const tectonicOrogens = new ImageLayer({
//           title: "tectonicOrogens",
//           source: new ImageWMS({
//             url: urlServer + "/DBworldinmaps/wms?",
//             params: {
//               service: "WMS",
//               layers: "DBworldinmaps:tectonic_orogens"
//             }
//           }),
//           visible: false
//         });

//         const tectonicPlates = new ImageLayer({
//           title: "tectonicPlates",
//           source: new ImageWMS({
//             url: urlServer + "/DBworldinmaps/wms?",
//             params: {
//               service: "WMS",
//               layers: "DBworldinmaps:tectonic_plates"
//             }
//           }),
//           visible: false
//         });

//         const volcanoes = new ImageLayer({
//           title: "volcanoes",
//           source: new ImageWMS({
//             url: urlServer + "/DBworldinmaps/wms?",
//             params: {
//               service: "WMS",
//               layers: "DBworldinmaps:volcanoes"
//             }
//           }),
//           visible: false
//         });

//         let styleEarthquakes = (feature) => {
//           const color = ["175, 234, 53", "175, 234, 53", "216, 215, 52", "243, 175, 48", "248, 145, 44", "245, 118, 41", "244, 100, 38", "242, 70, 36", "211, 58, 29", "180, 48, 23", "180, 48, 23", "180, 48, 23", "180, 48, 23"]
//           let magnitude = feature.get("mag");
//           if (Math.floor(magnitude) < 0) {
//             magnitude = 0;
//           }
//           const magnitudeColor = color[Math.floor(magnitude)];
//           return new Style({
//             image: new Circle({
//               radius: 3,
//               stroke: new Stroke({
//                 color: "rgb("+magnitudeColor+")",
//               }),
//               fill: new Fill({
//                 color: "rgba("+magnitudeColor + ", " + 0.3 + ")",

//               }),
//             })
//           })
//       };

//         const earthquakesMap = new VectorLayer({
//           title: "earthquakes",
//           source: new VectorSource({
//             format: new GeoJSON(),
//             url: "https://earthquake.usgs.gov/fdsnws/event/1/query?format=geojson"
//           }),
//           style: styleEarthquakes,
//           visible: false
//         })


//         const physicalMap = new TileLayer({
//           title: "physicalMap",
//           source: new TileWMS({
//             url: urlServer + "/basemap/wms",
//             params: {
//               service: "WMS",
//               layers: "basemap:physical_map"
//             }
//           }),
//           visible: false
//         });

//         const landCoverMap = new TileLayer({
//           title: "landCoverMap",
//           source: new TileWMS({
//             url: urlServer + "/basemap/wms",
//             params: {
//               service: "WMS",
//               layers: "basemap:land_cover"
//             }
//           }),
//           visible: true
//         });

//         const bathymetry = new ImageLayer({
//           title: "bathymetry",
//           source: new ImageWMS({
//             url: urlServer + "/basemap/wms?",
//             params: {
//               service: "WMS",
//               layers: "basemap:bathymetry"
//             }
//           }),
//           visible: true
//         });

//         const marineLabels = new ImageLayer({
//           title: "marineLabels",
//           source: new ImageWMS({
//             url: urlServer + "/basemap/wms?",
//             params: {
//               service: "WMS",
//               layers: "basemap:marine_labels"
//             }
//           }),
//           visible: true
//         });

//         switch(this.props.layerFromUrl) {
//           case "timezone":
//             UTCtimeZone.setVisible(true);
//             timeZone.setVisible(true);
//             break;
//           case "physical-map":
//             physicalMap.setVisible(true);
//             landCoverMap.setVisible(false);
//             break;
//           case "earthquakes":
//             earthquakesMap.setVisible(true);
//             tectonicBoundaries.setVisible(true);
//           default:
//             break
//         }
        
//         layersArray.push(aerialMap);
//         layersArray.push(osm);
//         layersArray.push(physicalMap);
//         layersArray.push(landCoverMap);
//         layersArray.push(bathymetry);
//         layersArray.push(countries);
//         layersArray.push(marineLabels);
//         layersArray.push(graticules20);
//         layersArray.push(tropicsCirclesEquatorDL);
//         layersArray.push(densityPopCountry);
//         layersArray.push(densityPop);
//         layersArray.push(timeZone);
//         layersArray.push(UTCtimeZone);
//         layersArray.push(tectonicBoundaries);
//         layersArray.push(tectonicOrogens);
//         layersArray.push(tectonicPlates);
//         layersArray.push(volcanoes);
//         layersArray.push(earthquakesMap);

//         this.olMap = new OlMap({
//             target: null,
//             controls: [scaleLineControl, zoomBtnControl],
//             view: new OlView({
//                 center: [0, 0],
//                 zoom: 2,
//                 //minZoom: 2,
//                 //projection: projection
//             }),
//             layers: layersArray
//         });
//         this.features = [];
//   }
  

//   componentDidMount() {
//     this.olMap.setTarget("map");
//     let sourceDrawLayer = new VectorSource();
//     let vectorDrawLayer = new VectorLayer({
//         title: "drawLayer",
//         source: sourceDrawLayer
//     });
//     this.olMap.addLayer(vectorDrawLayer);
//   }

//     render() {
//         return (
//             <div id="map" className="map-container">
//                 <MapContext.Provider value={this.olMap}>
//                     {this.props.children}
//                 </MapContext.Provider>
//             </div>
//         )
//     }
// }


// dev
import React, { Component } from 'react'
import OlMap from "ol/Map";
import OlView from "ol/View";

import ImageLayer from "ol/layer/Image.js";
import ImageWMS from "ol/source/ImageWMS.js";

import VectorSource from "ol/source/Vector.js";
import { Vector as VectorLayer } from "ol/layer.js";

import GeoJSON from "ol/format/GeoJSON";
import { bbox as bboxStrategy } from "ol/loadingstrategy.js";
import { ScaleLine, Zoom } from "ol/control.js";

import { Stroke, Style, Fill, Circle } from "ol/style.js";
import TileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import { OSM, XYZ } from "ol/source";

export const MapContext = React.createContext(undefined);
export default class Map extends Component {
    constructor(props) {
        super(props);
        // const
        const urlServer = "https://geoserver.infooni.com:8443/geoserver";
        //const urlServer = "http://51.68.229.243:8080//geoserver";

        // basic control
        const scaleLineControl = new ScaleLine();
        const zoomBtnControl = new Zoom();

        // create array with the layers
        const layersArray = [];

        // init openlayer map object
        const osm = new TileLayer({
            title: "osm",
            source: new OSM({crossOrigin: 'anonymous',}),
            visible: false
        });

        // init free aerial map
        const aerialMap = new TileLayer({
            title: "satellite",
            source: new XYZ({
            attributions: ['Powered by Esri',
                        'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
            attributionsCollapsible: false,
            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            maxZoom: 23,
            crossOrigin: 'anonymous',
        }),
        visible: false
        });

        const countries = new ImageLayer({
              title: "countries",
              source: new ImageWMS({
                url: urlServer + "/DBworldinmaps/wms?",
                params: {
                  service: "WMS",
                  layers: "DBworldinmaps:countries"
                },
                crossOrigin: 'anonymous',
              })
            });

            const tropicsCirclesEquatorDL = new ImageLayer({
              title: "tropicsCirclesEquatorDL",
              source: new ImageWMS({
                url: urlServer + "/basemap/wms",
                params: {
                  service: "WMS",
                  layers: "basemap:geographic_lines"
                },
                crossOrigin: 'anonymous',
              }),
              visible: false
            });

            const graticules20 = new ImageLayer({
              title: "graticules20",
              source: new ImageWMS({
                url: urlServer + "/basemap/wms",
                params: {
                  service: "WMS",
                  layers: "basemap:graticules_20"
                },
                crossOrigin: 'anonymous',
              }),
              visible: false
            });

            const densityPopCountry = new ImageLayer({
                title: "densityPopCountry",
                source: new ImageWMS({
                  url: urlServer + "/DBworldinmaps/wms",
                  params: {
                    service: "WMS",
                    layers: "DBworldinmaps:density_pop"
                  },
                  crossOrigin: 'anonymous',
                }),
                visible: false
              });

              const densityPop = new TileLayer({
                title: "densityPop",
                source: new TileWMS({
                  url: urlServer + "/people/wms",
                  params: {
                    service: "WMS",
                    layers: "people:density_pop_sat_2015_tiles"
                  },
                  crossOrigin: 'anonymous',
                }),
                visible: false
              });

              let timeZoneVectorSource = new VectorSource({
                format: new GeoJSON(),
                url: function(extent) {
                  return urlServer + "/DBworldinmaps/ows?" +
                  "service=WFS&version=1.1.0&request=GetFeature&" +
                  "typename=DBworldinmaps:world_time_zone&outputFormat=application/json&" +
                  "srsname=EPSG:3857&bbox="+ extent.join(",") + "," + "EPSG:3857";
                },
                strategy: bboxStrategy
              });

              let timeZone = new VectorLayer({
                title: "timeZone",
                source: timeZoneVectorSource,
                style: new Style({
                  stroke: new Stroke({
                    color: "rgba(0,135,213, 0.5)",
                    width: 1
                  }),
                  fill: new Fill({color: 'rgba(0,0,0,0)'})
                }),
                visible: false
              });

              let UTCtimeZone = new TileLayer({
                title: "timeZoneUTC",
                source: new TileWMS({
                  url: urlServer + "/DBworldinmaps/wms",
                  params: {
                    service: "WMS",
                    layers: "DBworldinmaps:UTC_time_zone"
                  },
                  crossOrigin: 'anonymous',
                }),
                visible: false
              });


        const tectonicBoundaries = new ImageLayer({
          title: "tectonicBoundaries",
          source: new ImageWMS({
            url: urlServer +"/DBworldinmaps/wms?",
            params: {
              service: "WMS",
              layers: "DBworldinmaps:tectonic_boundaries"
            },
            crossOrigin: 'anonymous',
          }),
          visible: false
        });
        const tectonicOrogens = new ImageLayer({
          title: "tectonicOrogens",
          source: new ImageWMS({
            url: urlServer + "/DBworldinmaps/wms?",
            params: {
              service: "WMS",
              layers: "DBworldinmaps:tectonic_orogens"
            },
            crossOrigin: 'anonymous',
          }),
          visible: false
        });

        const tectonicPlates = new ImageLayer({
          title: "tectonicPlates",
          source: new ImageWMS({
            url: urlServer + "/DBworldinmaps/wms?",
            params: {
              service: "WMS",
              layers: "DBworldinmaps:tectonic_plates"
            },
            crossOrigin: 'anonymous',
          }),
          visible: false
        });

        const volcanoes = new ImageLayer({
          title: "volcanoes",
          source: new ImageWMS({
            url: urlServer + "/DBworldinmaps/wms?",
            params: {
              service: "WMS",
              layers: "DBworldinmaps:volcanoes"
            },
            crossOrigin: 'anonymous',
          }),
          visible: false
        });

        let styleEarthquakes = (feature) => {
          const color = ["175, 234, 53", "175, 234, 53", "216, 215, 52", "243, 175, 48", "248, 145, 44", "245, 118, 41", "244, 100, 38", "242, 70, 36", "211, 58, 29", "180, 48, 23", "180, 48, 23", "180, 48, 23", "180, 48, 23"]
          let magnitude = feature.get("mag");
          if (Math.floor(magnitude) < 0) {
            magnitude = 0;
          }
          const magnitudeColor = color[Math.floor(magnitude)];
          return new Style({
            image: new Circle({
              radius: 3,
              stroke: new Stroke({
                color: "rgb("+magnitudeColor+")",
              }),
              fill: new Fill({
                color: "rgba("+magnitudeColor + ", " + 0.3 + ")",

              }),
            })
          })
      };

        const earthquakesMap = new VectorLayer({
          title: "earthquakes",
          source: new VectorSource({
            format: new GeoJSON(),
            url: "https://earthquake.usgs.gov/fdsnws/event/1/query?format=geojson"
          }),
          style: styleEarthquakes,
          visible: false
        })


        const physicalMap = new TileLayer({
          title: "physicalMap",
          source: new TileWMS({
            url: urlServer + "/basemap/wms",
            params: {
              service: "WMS",
              layers: "basemap:physical_map"
            },
            crossOrigin: 'anonymous',
          }),
          visible: false
        });

        const landCoverMap = new TileLayer({
          title: "landCoverMap",
          source: new TileWMS({
            url: urlServer + "/basemap/wms",
            params: {
              service: "WMS",
              layers: "basemap:land_cover"
            },
            crossOrigin: 'anonymous',
          }),
          visible: true
        });

        const bathymetry = new ImageLayer({
          title: "bathymetry",
          source: new ImageWMS({
            url: urlServer + "/basemap/wms?",
            params: {
              service: "WMS",
              layers: "basemap:bathymetry"
            },
            crossOrigin: 'anonymous',
          }),
          visible: true
        });

        const marineLabels = new ImageLayer({
          title: "marineLabels",
          source: new ImageWMS({
            url: urlServer + "/basemap/wms?",
            params: {
              service: "WMS",
              layers: "basemap:marine_labels"
            },
            crossOrigin: 'anonymous',
          }),
          visible: true
        });

        switch(this.props.layerFromUrl) {
          case "timezone":
            UTCtimeZone.setVisible(true);
            timeZone.setVisible(true);
            break;
          case "physical-map":
            physicalMap.setVisible(true);
            landCoverMap.setVisible(false);
            break;
          case "earthquakes":
            earthquakesMap.setVisible(true);
            tectonicBoundaries.setVisible(true);
          default:
            break
        }
        
        layersArray.push(aerialMap);
        layersArray.push(osm);
        layersArray.push(physicalMap);
        layersArray.push(landCoverMap);
        layersArray.push(bathymetry);
        layersArray.push(countries);
        layersArray.push(marineLabels);
        layersArray.push(graticules20);
        layersArray.push(tropicsCirclesEquatorDL);
        layersArray.push(densityPopCountry);
        layersArray.push(densityPop);
        layersArray.push(timeZone);
        layersArray.push(UTCtimeZone);
        layersArray.push(tectonicBoundaries);
        layersArray.push(tectonicOrogens);
        layersArray.push(tectonicPlates);
        layersArray.push(volcanoes);
        layersArray.push(earthquakesMap);

        this.olMap = new OlMap({
            target: null,
            controls: [scaleLineControl, zoomBtnControl],
            view: new OlView({
                center: [0, 0],
                zoom: 2,
                //minZoom: 2,
                //projection: projection
            }),
            layers: layersArray
        });
        this.features = [];
  }
  

  componentDidMount() {
    this.olMap.setTarget("map");
    let sourceDrawLayer = new VectorSource();
    let vectorDrawLayer = new VectorLayer({
        title: "drawLayer",
        source: sourceDrawLayer
    });
    this.olMap.addLayer(vectorDrawLayer);
  }

    render() {
        return (
            <div id="map" className="map-container">
                <MapContext.Provider value={this.olMap}>
                    {this.props.children}
                </MapContext.Provider>
            </div>
        )
    }
}
