import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Menu from '../layout/Menu';
import LayersManagement from '../tools/LayersManagement';
import Attributes from '../layout/Attributes';
import Map from "../gis/Map";
import AttributesTimeZone from '../layout/AttributesTimeZone';
import AttributesEarthquakes from '../layout/AttributesEarthquakes';
import Nav from "../layout/Nav";
import TabMenu from "../layout/TabMenu";
import EditPoint from "../tools/point/EditPoint";
import ListPoints from "../tools/point/ListPoints";
import EditLine from "../tools/line/EditLine";
import ListLines from "../tools/line/ListLines";
import EditPolygon from "../tools/polygon/EditPolygon";
import ListPolygons from "../tools/polygon/ListPolygons";
import ExportView from "../export/ExportView";
export class ViewerPage extends Component {
    
    render() {
        var tabs = this.props.layout.tab.map(tab => {
            switch (tab) {
                /* =========================================================== */
                /* Layers                                                       */
                /* =========================================================== */
                case "layersManagement":
                    return (
                        <LayersManagement key="newPointTab"/>
                    );

                /* =========================================================== */
                /* Point                                                       */
                /* =========================================================== */
                case "newPointTab":
                    return (
                        <TabMenu
                            key="newPointTab"
                            title="New Point"
                            name="newPointTab"
                        >
                            <EditPoint type="newPointTab" />
                        </TabMenu>
                    );
                case "editPointTab":
                    return (
                        <TabMenu
                            key="editPointTab"
                            title="Edit Point"
                            name="editPointTab"
                        >
                            <EditPoint type="editPointTab" />
                        </TabMenu>
                    );
                case "pointTab":
                    return (
                        <TabMenu key="pointTab" title="Points" name="pointTab">
                            <ListPoints history={this.props.history} />
                        </TabMenu>
                    );
                /* =========================================================== */
                /* Line                                                       */
                /* =========================================================== */
                case "newLineTab":
                    return (
                        <TabMenu
                            key="newLineTab"
                            title="New Line"
                            name="newLineTab"
                        >
                            <EditLine type="newLineTab" />
                        </TabMenu>
                    );
                case "editLineTab":
                    return (
                        <TabMenu
                            key="editLineTab"
                            title="Edit Line"
                            name="editLineTab"
                        >
                            <EditLine type="editLineTab" />
                        </TabMenu>
                    );
                case "lineTab":
                    return (
                        <TabMenu key="lineTab" title="Lines" name="lineTab">
                            <ListLines history={this.props.history} />
                        </TabMenu>
                    );

                /* =========================================================== */
                /* Polygon                                                     */
                /* =========================================================== */
                case "newPolygonTab":
                    return (
                        <TabMenu
                            key="newPolygonTab"
                            title="New Polygon"
                            name="newPolygonTab"
                        >
                            <EditPolygon type="newPolygonTab" />
                        </TabMenu>
                    );
                case "editPolygonTab":
                    return (
                        <TabMenu
                            key="editPolygonTab"
                            title="Edit Polygon"
                            name="editPolygonTab"
                        >
                            <EditPolygon type="editPolygonTab" />
                        </TabMenu>
                    );
                case "polygonTab":
                    return (
                        <TabMenu key="polygonTab" title="Polygon" name="polygonTab">
                            <ListPolygons history={this.props.history} />
                        </TabMenu>
                    );

                /* =========================================================== */
                /* Export                                                      */
                /* =========================================================== */
                case "exportTab":
                    return (
                        <ExportView key="exportTab" title="exportTab" name="exportTab" history={this.props.history} />
                    );
                default:
                    return null;
            }
        });
        return (
            <div className="wim-viewer">
                <Menu />
                {this.props.layout.tab.map(tab => {
                    switch (tab) {
                        case "navTab":
                            return <Nav key="navTab" name="navTab" />;
                        default:
                            return null;

                    }
                })}
                <Map layerFromUrl={this.props.match.params.layer}>
                    {tabs}
                    {false && (<LayersManagement />)}
                    {false && (<Attributes />)}
                    {false && ( <AttributesEarthquakes/>)}
                    {false && (<AttributesTimeZone />)}
                </Map>
            </div>
        )
    }
}

ViewerPage.propTypes = {
    layout: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    layout: state.layout
});

export default connect(mapStateToProps, undefined)(ViewerPage);
