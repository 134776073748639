import {
    ADD_LEGEND_ELEMENT, SELECT_LEGEND_ELEMENT, UPDATE_LEGEND_ELEMENT, CLEAR_LEGEND_ELEMENT
} from "../actions/typesActions";

const initialState = {
    legendElements: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_LEGEND_ELEMENT:
            return {
                ...state,
                legendElements: state.legendElements.length > 0 ? [...state.legendElements, action.legendElement] : [action.legendElement]
            };
        case SELECT_LEGEND_ELEMENT:
            return {
                ...state,
                selectedLegendElement: action.legendElement
            };
        case UPDATE_LEGEND_ELEMENT:
            return {
                ...state,
                legendElements: state.legendElements.map((legendElement, i) => {
                    if (i === action.legendElement.id) {
                        return action.legendElement.updatedLegendElement;
                    } else {
                        return legendElement;
                    }
                })
            };
        case CLEAR_LEGEND_ELEMENT:
            return {
                ...state,
                legendElements: []
            };
        default:
            return state;
    }
}