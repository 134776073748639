import React, { Component } from "react";
import PropTypes from "prop-types";
import { addTab } from "../../actions/layoutActions";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class Menu extends Component {
    openMenu = (menuItem) => {
        this.props.addTab(menuItem);
    };
    render() {
        return (
            <div className="wim-menu-btn__container ol-unselectable ol-control">
                <button className="wim-menu-btn">
                    <FontAwesomeIcon icon={["fas", "bars"]} onClick={() => this.openMenu("navTab")} />
                </button>
            </div>

        );
    }
}

Menu.propTypes = {
    addTab: PropTypes.func.isRequired,
    layout: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    layout: state.layout
});

const mapDispatchToProps = dispatch => ({
    addTab: tab => dispatch(addTab(tab))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu);
