// GET_ATTRIBUTES
import { ADD_INTERACTION } from "./typesActions";
import { REMOVE_INTERACTION } from "./typesActions";

// features
import { SELECT_POINT, SELECT_LINE, SELECT_POLYGON } from "./typesActions";

export const addInteraction = (interaction = {}) => {
    return dispatch => {
        return dispatch({
            type: ADD_INTERACTION,
            interaction
        });
    };
};

export const removeInteraction = index => {
    return dispatch => {
        return dispatch({
            type: REMOVE_INTERACTION,
            index
        });
    };
};

export const selectPoint = feature => {
    return {
        type: SELECT_POINT,
        feature
    }
}

export const selectLine = feature => {
    return {
        type: SELECT_LINE,
        feature
    }
}

export const selectPolygon = feature => {
    return {
        type: SELECT_POLYGON,
        feature
    }
}