import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MapContext } from "../gis/Map";
import Legend from "../layout/Legend";
import TabMenu from '../layout/TabMenu'
import { Stroke, Style, Fill, Circle} from "ol/style.js";

export class LayersManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tectonicBoundaries: false,
            tectonicOrogens: false,
            tectonicPlates: false,
            earthquakes: false,
            volcanoes: false,
            timeZone: false,
            densityPop: false,
            densityPopCountry: false,
            countries: true,
            osm: false,
            satellite: false,
            tropicsCirclesEquatorDL:false,
            graticules20: false,
            bathymetry: true,
            marineLabels: true,
            physicalMap: false,
            landCoverMap: true
        }

        this.highlightStyle = new Style({
            fill: new Fill({
              color: 'rgba(255,255,255,0.7)'
            }),
            stroke: new Stroke({
              color: '#3399CC',
              width: 3
            })
          });
        this.highlightStyleEarthquakes = (feature) => {
            const color = ["175, 234, 53", "175, 234, 53", "216, 215, 52", "243, 175, 48", "248, 145, 44", "245, 118, 41", "244, 100, 38", "242, 70, 36", "211, 58, 29", "180, 48, 23", "180, 48, 23", "180, 48, 23", "180, 48, 23"]
            let magnitude = feature.get("mag");
            if (Math.floor(magnitude) < 0) {
              magnitude = 0;
            }
            const magnitudeColor = color[Math.floor(magnitude)];
            return new Style({
              image: new Circle({
                radius: 6,
                stroke: new Stroke({
                  color: "rgb("+magnitudeColor+")",
                  width:3
                }),
                fill: new Fill({
                  color: "rgba("+magnitudeColor + ", " + 1 + ")",
  
                }),
              })
            })
        };
        this.timeZoneSelected = null;
        this.earthquakesSelected = null;
        
    }

    toggleTropicsCirclesEquatorDL = () => {
        this.setState({tropicsCirclesEquatorDL: !this.state.tropicsCirclesEquatorDL}, function () {
            if(this.state.tropicsCirclesEquatorDL) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "tropicsCirclesEquatorDL") {
                        layer.setVisible(true);
                    }
                });
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "tropicsCirclesEquatorDL") {
                        layer.setVisible(false);
                    }
                });
            }  
        })
    }

    toggleGraticules20 = () => {
        this.setState({graticules20: !this.state.graticules20}, function () {
            if(this.state.graticules20) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "graticules20") {
                        layer.setVisible(true);
                    }
                });
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "graticules20") {
                        layer.setVisible(false);
                    }
                });
            }  
        })
    }

    toggledensityPop = () => {
        this.setState({densityPop: !this.state.densityPop}, function () {
            if(this.state.densityPop) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "densityPop") {
                        layer.setVisible(true);
                    }
                });
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "densityPop") {
                        layer.setVisible(false);
                    }
                });
            }  
        })
    }

    toggledensityPopCountry = () => {
        this.setState({densityPopCountry: !this.state.densityPopCountry}, function () {
            if(this.state.densityPopCountry) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "densityPopCountry") {
                        layer.setVisible(true);
                    }
                });
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "densityPopCountry") {
                        layer.setVisible(false);
                    }
                });
            }  
        })
    }

    toggleCountries = () => {
        this.setState({countries: !this.state.countries}, function () {
            if(this.state.countries) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "countries") {
                        layer.setVisible(true);
                    }
                });
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "countries") {
                        layer.setVisible(false);
                    }
                });
            }  
        })
    }

    toggleTimeZone = () => {
        this.setState({timeZone: !this.state.timeZone}, function () {
            if(this.state.timeZone) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "timeZone" || layer.get("title") === "timeZoneUTC") {
                        layer.setVisible(true);
                    }
                });
                this.context.on('pointermove', this.selectTimeZone);
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "timeZone" || layer.get("title") === "timeZoneUTC") {
                        layer.setVisible(false);
                        this.context.un('pointermove', this.selectTimeZone);
                    }
                });
            }  
        })
    }

    selectTimeZone = (e) => {
        if (this.timeZoneSelected !== null) {
            this.timeZoneSelected.setStyle(undefined);
            this.timeZoneSelected = null;
        }
        this.context.forEachFeatureAtPixel(e.pixel, (f, layer) => {
            this.timeZoneSelected = f;
            f.setStyle(this.highlightStyle);
            return true;
        }, {
            layerFilter: function (layer) {
                return layer.get('title') === 'timeZone';
            }
        });
    
    };
    toggleEarthquakes = () => {
        this.setState({earthquakes: !this.state.earthquakes}, function () {
            if(this.state.earthquakes) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "earthquakes") {
                        layer.setVisible(true);
                    }
                });
                // this.context.on('pointermove', this.selectEarthquake);
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "earthquakes") {
                        layer.setVisible(false);
                        // this.context.un('pointermove', this.selectEarthquake);
                    }
                });
            }  
        })
    }

    toggleVolcanoes = () => {
        this.setState({volcanoes: !this.state.volcanoes}, function () {
            if(this.state.volcanoes) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "volcanoes") {
                        layer.setVisible(true);
                    }
                });
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "volcanoes") {
                        layer.setVisible(false);
                    }
                });
            }  
        })
    }

    toggleTectonicBoundaries = () => {
        this.setState({tectonicBoundaries: !this.state.tectonicBoundaries}, function () {
            if(this.state.tectonicBoundaries) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "tectonicBoundaries") {
                        layer.setVisible(true);
                    }
                });
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "tectonicBoundaries") {
                        layer.setVisible(false);
                    }
                });
            }  
        })
    }

    toggleTectonicOrogens = () => {
        this.setState({tectonicOrogens: !this.state.tectonicOrogens}, function () {
            if(this.state.tectonicOrogens) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "tectonicOrogens") {
                        layer.setVisible(true);
                    }
                });
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "tectonicOrogens") {
                        layer.setVisible(false);
                    }
                });
            }  
        })
    }

    toggleTectonicPlates = () => {
        this.setState({tectonicPlates: !this.state.tectonicPlates}, function () {
            if(this.state.tectonicPlates) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "tectonicPlates") {
                        layer.setVisible(true);
                    }
                });
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "tectonicPlates") {
                        layer.setVisible(false);
                    }
                });
            }  
        })
    }

    toggleOSM = () => {
        this.setState({osm: !this.state.osm}, function () {
            if(this.state.osm) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "osm") {
                        layer.setVisible(true);
                    }
                });
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "osm") {
                        layer.setVisible(false);
                    }
                });
            }  
        })
    }

    toggleSatellite = () => {
        this.setState({satellite: !this.state.satellite}, function () {
            if(this.state.satellite) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "satellite") {
                        layer.setVisible(true);
                    }
                });
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === "satellite") {
                        layer.setVisible(false);
                    }
                });
            }  
        })
    }

    toggleLayer = (layerName) => {
        this.setState({[layerName]: !this.state[layerName]}, function () {
            if(this.state[layerName]) {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === layerName) {
                        layer.setVisible(true);
                    }
                });
            } else {
                this.context.getLayers().forEach(layer => {
                    if(layer.get("title") === layerName) {
                        layer.setVisible(false);
                    }
                });
            }  
        })
    }

    render() {
        return (
            <TabMenu
                key="layersManagement"
                title="Map explorer - layers"
                name="layersManagement"
                >

                    <div className="container wim-menu">
                        <div className="mt-3">
                            <h2>Geographic Lines</h2>
                            <ul>
                                <li className="form-check" onClick={this.toggleTropicsCirclesEquatorDL}>
                                    {this.state.tropicsCirclesEquatorDL && (<input type="checkbox" id="tropicsCirclesEquatorDL" name="tropicsCirclesEquatorDL" className="form-check-input" checked/>)}
                                    {!this.state.tropicsCirclesEquatorDL && (<input type="checkbox" id="tropicsCirclesEquatorDL" name="tropicsCirclesEquatorDL" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="densitropicsCirclesEquatorDLtyPop">Tropical and polar circles, equator and International Date Line</label>
                                </li>
                                <li className="form-check" onClick={this.toggleGraticules20}>
                                    {this.state.graticules20 && (<input type="checkbox" id="graticules20" name="graticules20" className="form-check-input" checked/>)}
                                    {!this.state.graticules20 && (<input type="checkbox" id="graticules20" name="graticules20" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="graticules20">Graticules 20°</label>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Demography</h2>
                            <ul>
                                <li className="form-check" onClick={this.toggledensityPop}>
                                    {this.state.densityPop && (<input type="checkbox" id="densityPop" name="densityPop" className="form-check-input" checked/>)}
                                    {!this.state.densityPop && (<input type="checkbox" id="densityPop" name="densityPop" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="densityPop">density of population for each km² (2015)</label>
                                </li>
                                <li className="form-check" onClick={this.toggledensityPopCountry}>
                                    {this.state.densityPopCountry && (<input type="checkbox" id="densityPopCountry" name="densityPopCountry" className="form-check-input" checked/>)}
                                    {!this.state.densityPopCountry && (<input type="checkbox" id="densityPopCountry" name="densityPopCountry" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="densityPopCountry">density of population by countries (2020)</label>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>World maps</h2>
                            <ul>
                                <li className="form-check" onClick={this.toggleTimeZone}>
                                    {this.state.timeZone && (<input type="checkbox" id="timeZone" name="timeZone" className="form-check-input" checked/>)}
                                    {!this.state.timeZone && (<input type="checkbox" id="timeZone" name="timeZone" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="timeZone">time zones</label>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Geology</h2>
                            <ul>
                                <li className="form-check" onClick={this.toggleEarthquakes}>
                                    {this.state.earthquakes && (<input type="checkbox" id="earthquakes" name="earthquakes" className="form-check-input" checked/>)}
                                    {!this.state.earthquakes && (<input type="checkbox" id="earthquakes" name="earthquakes" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="earthquakes">Earthquakes in the last 30 days (click on the icons for more information)</label>
                                </li>
                                <li className="form-check" onClick={this.toggleVolcanoes}>
                                    {this.state.volcanoes && (<input type="checkbox" id="volcanoes" name="volcanoes" className="form-check-input" checked/>)}
                                    {!this.state.volcanoes && (<input type="checkbox" id="volcanoes" name="volcanoes" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="volcanoes">Volcanoes</label>
                                </li>
                                <li className="form-check" onClick={this.toggleTectonicBoundaries}>
                                    {this.state.tectonicBoundaries && (<input type="checkbox" id="tectonicBoundaries" name="tectonicBoundaries" className="form-check-input" checked/>)}
                                    {!this.state.tectonicBoundaries && (<input type="checkbox" id="tectonicBoundaries" name="tectonicBoundaries" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="tectonicBoundaries">Tectonic plates boundaries</label>
                                </li>
                                <li className="form-check" onClick={this.toggleTectonicOrogens}>
                                    {this.state.tectonicOrogens && (<input type="checkbox" id="tectonicOrogens" name="tectonicOrogens" className="form-check-input" checked/>)}
                                    {!this.state.tectonicOrogens && (<input type="checkbox" id="tectonicOrogens" name="tectonicOrogens" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="tectonicOrogens">Orogenic belt</label>
                                </li>
                                <li className="form-check" onClick={this.toggleTectonicPlates}>
                                    {this.state.tectonicPlates && (<input type="checkbox" id="tectonicPlates" name="tectonicPlates" className="form-check-input" checked/>)}
                                    {!this.state.tectonicPlates && (<input type="checkbox" id="tectonicPlates" name="tectonicPlates" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="tectonicPlates">tectonic plates</label>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Countries map</h2>
                            <ul>
                                <li className="form-check" onClick={this.toggleCountries}>
                                    {this.state.countries && (<input type="checkbox" id="countries" name="countries" className="form-check-input" checked/>)}
                                    {!this.state.countries && (<input type="checkbox" id="countries" name="countries" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="countries">countries</label>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Oceans, seas and gulfs</h2>
                            <ul>
                                <li className="form-check" onClick={() => this.toggleLayer("marineLabels")}>
                                    {this.state.marineLabels && (<input type="checkbox" id="marineLabels" name="marineLabels" className="form-check-input" checked/>)}
                                    {!this.state.marineLabels && (<input type="checkbox" id="marineLabels" name="marineLabels" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="marineLabels">labels</label>
                                </li>
                                <li className="form-check" onClick={() => this.toggleLayer("bathymetry")}>
                                    {this.state.bathymetry && (<input type="checkbox" id="bathymetry" name="bathymetry" className="form-check-input" checked/>)}
                                    {!this.state.bathymetry && (<input type="checkbox" id="bathymetry" name="bathymetry" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="bathymetry">bathymetry</label>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Background map</h2>
                            <ul>
                                <li className="form-check" onClick={() => this.toggleLayer("landCoverMap")}>
                                    {this.state.landCoverMap && (<input type="checkbox" id="landCoverMap" name="landCoverMap" className="form-check-input" checked/>)}
                                    {!this.state.landCoverMap && (<input type="checkbox" id="landCoverMap" name="landCoverMap" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="landCoverMap">land cover map</label>
                                </li>
                                <li className="form-check" onClick={() => this.toggleLayer("physicalMap")}>
                                    {this.state.physicalMap && (<input type="checkbox" id="physicalMap" name="physicalMap" className="form-check-input" checked/>)}
                                    {!this.state.physicalMap && (<input type="checkbox" id="physicalMap" name="physicalMap" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="physicalMap">physical map</label>
                                </li>
                                <li className="form-check" onClick={this.toggleOSM}>
                                    {this.state.osm && (<input type="checkbox" id="osm" name="osm" className="form-check-input" checked/>)}
                                    {!this.state.osm && (<input type="checkbox" id="osm" name="osm" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="osm">street map</label>
                                </li>
                                { false && (
                                <li className="form-check" onClick={this.toggleSatellite}>
                                    {this.state.satellite && (<input type="checkbox" id="satellite" name="satellite" className="form-check-input" checked/>)}
                                    {!this.state.satellite && (<input type="checkbox" id="satellite" name="satellite" className="form-check-input"/>)}
                                    <label className="form-check-label" htmlFor="satellite">satellite</label>
                                </li>
                                )}
                            </ul>
                        </div>
                    </div>
            </TabMenu>
            
        )
    }
}
LayersManagement.propTypes = {
    layout: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    layout: state.layout
});

export default connect(mapStateToProps, undefined)(LayersManagement);

LayersManagement.contextType = MapContext;
